<template>
    <div
        :class="[
            'form-field',
            'op-date-textfield',
            { 'op-date-textfield-readonly': readonly },
            { disabled: disabled }
        ]"
    >
        <label class="title" v-if="label" :for="myid">{{ label }}</label>
        <div class="restrict" v-if="!readonly && required">
            <div
                v-if="required"
                :class="[
                    'restrict-item',
                    checkRequired ? 'checked-ok' : 'checked-error'
                ]"
            >
                必
            </div>
        </div>
        <div class="form-field-input">
            <span v-if="readonly" class="op-textfield-inner readonly">
                {{ dateModel | date }}
            </span>
            <span v-else>
                <v-date-picker
                    v-model="dateModel"
                    :min-date="this.mindate"
                    :max-date="this.maxdate"
                    :update-on-input="false"
                    :attributes="attributes"
                >
                    <template
                        v-slot="{ inputValue, togglePopover, inputEvents }"
                    >
                        <div>
                            <button
                                class="calendarbutton"
                                @click="
                                    togglePopover({ placement: 'auto-start' })
                                "
                                tabindex="-1"
                            >
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 20 20"
                                    width="20px"
                                >
                                    <path
                                        d="M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"
                                    ></path>
                                </svg>
                            </button>
                            <input
                                type="text"
                                class="op-textfield-inner"
                                v-on="inputEvents"
                                :value="inputValue"
                                :id="myid"
                                @change="oncheck"
                                autocomplete="off"
                                :disabled="disabled"
                                ref="input"
                            />
                        </div>
                    </template>
                    <!--
                                        :class="[
                        `shadow appearance-none border rounded-l w-full py-2 px-3 `,
                        { 'border-red-600': checkRequired }
                    ]" -->
                </v-date-picker>
                <button
                    type="button"
                    class="op-textfield-clear-button"
                    @click="dateModel = null"
                    tabindex="-1"
                >
                    ×
                </button>
            </span>
        </div>
    </div>
</template>

<script>
import DateUtils from "@/DateUtils";

export default {
    name: "OpDateTextField",
    model: {
        prop: "inputModel",
        event: "input"
    },
    props: {
        inputModel: Date,
        required: Boolean,
        name: String,
        id: String,
        label: String,
        disabled: Boolean,
        readonly: Boolean,
        mindate: Date,
        maxdate: Date
    },
    data: function() {
        return {
            dateModel: this.inputModel,
            validflag: false,
            myid: this.id,
            attributes: [
                {
                    key: "today",
                    highlight: { fillMode: "light" },
                    dates: new Date()
                },
                {
                    bar: "red",
                    dates: {
                        weekdays: [1]
                    }
                },
                {
                    bar: "blue",
                    dates: {
                        weekdays: [7]
                    }
                },
                {
                    bar: "red",
                    dates: this.$store.state.bankNonBusinessDays
                }
            ]
        };
    },
    watch: {
        dateModel: function(value) {
            this.$emit("input", value);
        },
        validflag: function(value) {
            if (this.name) {
                this.$emit("changed-valid", this.name, value);
            }
        },
        inputModel: function(value) {
            this.dateModel = value;
        },
        required: function() {
            this._checkRequired();
        }
    },
    methods: {
        _checkRequired() {
            let ret = true;
            if (this.required) {
                ret = this.dateModel != null;
            }
            this.validflag = ret;
            return ret;
        },
        focus: function() {
            this.$refs.input.focus();
        },
        oncheck: function(event) {
            let val = event.target.value;
            let dt = DateUtils.convertToDateConvenience(val);
            if (dt) {
                val = DateUtils.toString8(dt);
                event.target.value = val;
            }
        }
    },
    computed: {
        checkRequired() {
            return this._checkRequired();
        }
    },
    mounted: function() {
        this._checkRequired();
        this.$emit("changed-valid", this.name, this.validflag);
    },
    created: function() {
        if (!this.myid) {
            this.$store.commit("inclementIdseq");
            this.myid = "oppo2020" + this.$store.state.idseq;
        }
    }
};
</script>
<style scoped>
.op-textfield-clear-button {
    z-index: 2;
    position: absolute;
    right: 5px;
    top: 6px;
    border-style: none;
    padding: 2px;
    margin: 3px;
    box-shadow: none;
}
.calendarbutton {
    z-index: 2;
    position: absolute;
    left: 5px;
    top: 5px;
    border-style: none;
    padding: 4px;
    box-shadow: none;
    border-radius: 0;
    fill: #999;
}
.op-textfield-inner {
    padding-left: 32px;
    padding-right: 32px;
    text-align: left;
}
.op-date-textfield-readonly .form-field-input {
    padding-left: 0px;
    padding-right: 0px;
    text-align: left;
}
</style>
