<template>
    <div class="content">
        <h3>お知らせ</h3>
        <ul class="infoboard">
            <li class="hg1">
                <div class="infohead">
                    <div class="infodate">2024.09.12</div>
                    <div class="infomark">バグ修正</div>
                </div>
                <div class="infobody">
                    <ul>
                        <li>
                            受注/発注の編集で売上/原価が１件しかない場合にも「...複数存在...」のアラートが表示されるバグを修正しました。
                        </li>
                        <li>
                            案件管理の「案件」で事業部を指定しても何もヒットしないバグを修正しました。
                        </li>
                    </ul>
                </div>
            </li>
            <li class="hg2">
                <div class="infohead">
                    <div class="infodate">2024.09.09</div>
                    <div class="infomark">機能改善</div>
                </div>
                <div class="infobody">
                    <ul>
                        <li>
                            案件管理にある検索条件:案件担当部署にて事業部での選択も可能にしました。
                        </li>
                        <li>
                            部署および取引先の入力において有効期限内の値のみ選択できるように修正しました。
                        </li>
                        <li>
                            受注および発注の入力において売上日または計上日を自動で変更されない場合に警告メッセージを出すようにしました。
                        </li>
                        <li>
                            取引先の選択にて文字を入力後にEnterキーを押した際、候補が複数ある場合はドロップダウンリストを表示するように変更しました。
                        </li>
                    </ul>
                </div>
            </li>
            <li class="hg1">
                <div class="infohead">
                    <div class="infodate">2024.04.18</div>
                    <div class="infomark">機能変更</div>
                </div>
                <div class="infobody">
                    「売上達成率」にて「履歴と比較表示」をする際に表示中に期のデータのみを対象とするように変更。
                </div>
            </li>
            <li class="hg2">
                <div class="infohead">
                    <div class="infodate">2024.02.11</div>
                    <div class="infomark">機能改善</div>
                </div>
                <div class="infobody">
                    <ul>
                        <li>
                            「案件管理」のデフォルトの検索条件を「通期」に変更しました。
                        </li>
                        <li>
                            「案件管理」のフィルターにを「検収日と売上日の値」と「納品日と計上日の値」を追加しました。
                        </li>
                        <li>
                            「売上達成率」の詳細「受注件名」に取引先名の表示を追加しました。
                        </li>
                    </ul>
                </div>
            </li>
            <li class="hg1">
                <div class="infohead">
                    <div class="infodate">2023.06.25</div>
                    <div class="infomark">機能改善</div>
                </div>
                <div class="infobody">
                    「売上達成率」にて「履歴と比較表示」をした状態で明細を表示した際の表示速度を改善しました。
                </div>
            </li>
            <li class="hg1">
                <div class="infohead">
                    <div class="infodate">2023.05.12</div>
                    <div class="infomark">仕様バグ修正</div>
                </div>
                <div class="infobody">
                    「売上達成率」にて他年度データと比較した際、表示年度に存在していない部署も表示するように修正
                </div>
            </li>
            <li class="hg1">
                <div class="infohead">
                    <div class="infodate">2022.10.03</div>
                    <div class="infomark">機能追加</div>
                </div>
                <div class="infobody">
                    データ出力機能「売上/原価詳細」を追加しました。（管理ユーザーのみ）
                </div>
            </li>
            <li class="hg1">
                <div class="infohead">
                    <div class="infodate">2022.4.25</div>
                    <div class="infomark">更新</div>
                </div>
                <div class="infobody">
                    認証・認可で使用しているKeycloakのバージョンを更新しました。
                    そのため、ログイン画面のデザイン等が変更されました。
                </div>
            </li>
            <li class="hg1">
                <div class="infohead">
                    <div class="infodate">2022.4.25</div>
                    <div class="infomark">お試し機能追加</div>
                </div>
                <div class="infobody">
                    お試し機能として、右サイドパネルに「<op-checkbox
                        label="ダークモードα"
                        v-model="$store.state.mopTheme.isDark"
                    ></op-checkbox
                    >」を用意しました。気になる方はお試しください。
                </div>
            </li>
            <li class="hg1">
                <div class="infohead">
                    <div class="infodate">2022.4.25</div>
                    <div class="infomark">仕様変更</div>
                </div>
                <div class="infobody">
                    [案件詳細]
                    <ul>
                        <li>
                            受注ヘッダに発注金額を表示するようにしました。
                        </li>
                    </ul>
                </div>
            </li>
            <li class="hg2">
                <div class="infohead">
                    <div class="infodate">2022.4.25</div>
                    <div class="infomark">仕様変更</div>
                </div>
                <div class="infobody">
                    <p>
                        売掛回収の消費税計算時に数円ズレる場合があったので、消費税率の持ち方を変更（売上→受注、原価→発注）しました。
                        それに伴い、[案件詳細] の表示および [日繰り表]
                        の計算方法も変更しました。
                    </p>
                    [案件詳細]
                    <ul>
                        <li>
                            売上からに消費税率を削除
                        </li>
                        <li>
                            受注にに消費税率を追加
                        </li>
                        <li>
                            原価からに消費税率を削除
                        </li>
                        <li>
                            発注にに消費税率を追加
                        </li>
                    </ul>
                </div>
            </li>
            <li class="hg1">
                <div class="infohead">
                    <div class="infodate">2022.4.25</div>
                    <div class="infomark">仕様変更</div>
                </div>
                <div class="infobody">
                    [取引先マスタ]
                    <ul>
                        <li>
                            入金サイトと支払サイトに0ヵ月を設定できるようにしました。
                        </li>
                    </ul>
                </div>
            </li>
            <li class="hg1">
                <div class="infohead">
                    <div class="infodate">2022.4.25</div>
                    <div class="infomark">バグ修正</div>
                </div>
                <div class="infobody">
                    [売上達成率]
                    <ul>
                        <li>
                            他の期と比較に際に通期／上期／下期の変更が出来ないバグを修正しました。
                        </li>
                    </ul>
                </div>
            </li>
            <li class="hg1">
                <div class="infohead">
                    <div class="infodate">2022.4.25</div>
                    <div class="infomark">機能改善</div>
                </div>
                <div class="infobody">
                    一時処理の負荷が上がりアクセス不能になることがあったので、安定稼働するように、一部SQLのチューニング、Indexの追加、プロセス数の追加を行いました。
                </div>
            </li>
            <li class="hg1">
                <div class="infohead">
                    <div class="infodate">2021.5.17</div>
                    <div class="infomark">機能追加</div>
                </div>
                <div class="infobody">
                    案件・受注・発注の削除機能を追加しました
                </div>
            </li>
            <li class="hg1">
                <div class="infohead">
                    <div class="infodate">2021.4.22</div>
                    <div class="infomark">機能追加</div>
                </div>
                <div class="infobody">
                    「月別集計表」（管理者のみ）を追加しました
                </div>
            </li>
            <li class="hg2">
                <div class="infohead">
                    <div class="infodate">2021.4.22</div>
                    <div class="infomark">機能追加</div>
                </div>
                <div class="infobody">
                    [案件検索]
                    <ul>
                        <li>
                            （受注）売上が複数件ある受注と、売上額と受注額が不一致の受注にマークを表示するようにしました
                        </li>
                        <li>
                            （発注）原価が複数件ある発注と、原価額と発注額が不一致の発注にマークを表示するようにしました
                        </li>
                        <li>ページ送り機能を実装しました</li>
                    </ul>
                </div>
            </li>
            <li class="hg2">
                <div class="infohead">
                    <div class="infodate">2021.4.22</div>
                    <div class="infomark">仕様変更</div>
                </div>
                <div class="infobody">
                    [案件検索]
                    <ul>
                        <li>一覧の表示項目を見直しました</li>
                    </ul>
                    [案件詳細]
                    <ul>
                        <li>表示項目および並び順を見直しました</li>
                        <li>少々見た目も変更しました</li>
                    </ul>
                </div>
            </li>
            <li class="hg1">
                <div class="infohead">
                    <div class="infodate">2021.4.22</div>
                    <div class="infomark">バグ修正</div>
                </div>
                <div class="infobody">
                    [発注 編集／複製]
                    <ul>
                        <li>支払日が自動計算されないバグを修正しました。</li>
                    </ul>
                </div>
            </li>
            <li class="hg2">
                <div class="infohead">
                    <div class="infodate">2021.3.22</div>
                    <div class="infomark">仕様変更</div>
                </div>
                <div class="infobody">
                    [案件詳細]
                    <ul>
                        <li>
                            受注の受注金額および検収日を変更した際、売上が１件のみの場合に、その売上金額および計上日に変更した受注金額および検収日を自動で設定する機能を追加しました。
                        </li>
                        <li>
                            発注と同様に発注金額および納品日のを変更した際、原価が１件のみの場合に、その原価金額および計上日を変更した発注金額および納品日を自動で設定する機能を追加しました。
                        </li>
                    </ul>
                </div>
            </li>
            <li>
                <div class="infohead">
                    <div class="infodate">2021.3.22</div>
                    <div class="infomark">仕様変更</div>
                </div>
                <div class="infobody">
                    [案件一覧]
                    受注のない案件は、受注や発注に関する条件を無視して一覧に表示するようにし、受注ステータスのところにメッセージを表示するようにしました。
                </div>
            </li>
            <li>
                <div class="infohead">
                    <div class="infodate">2021.3.22</div>
                    <div class="infomark">バグ修正</div>
                </div>
                <div class="infobody">
                    [案件検索] 日付の範囲入力に関するバグを修正しました。
                </div>
            </li>
            <li class="hg1">
                <div class="infohead">
                    <div class="infodate">2021.3.8</div>
                    <div class="infomark">仕様変更</div>
                </div>
                <div class="infobody hg1">
                    <p>
                        案件管理の検索結果から詳細へ移動する際にクリックする範囲を「行全体」から「行番号のみ」へ変更しました。
                    </p>
                </div>
            </li>
            <li class="hg1">
                <div class="infohead">
                    <div class="infodate">2021.3.8</div>
                    <div class="infomark">試しに</div>
                </div>
                <div class="infobody hg1">
                    <p>
                        ホーム（トップ）ページに各機能へのリンクとお知らせを追加してみました。
                    </p>
                    <p>単色ワイヤーフレームのべた書きです。</p>
                    <p class="small">
                        動作確認時にいちいちハンバーガーを押すのが面倒になってしまったのでメニューをトップページに乗せました。苦情は桂田まで。。
                    </p>
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import OpCheckbox from "../OpCheckbox.vue";
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
    name: "Dashboard",
    components: {
        OpCheckbox
        // HelloWorld
    },
    data: function() {
        return {};
    },
    methods: {},
    created: async function() {}
};
</script>

<style scoped>
.content > h3 {
    text-align: center;
    font-weight: normal;
    border-top: 1px solid #999;
    width: calc(90vw - 60px);
    margin: 10px auto;
    padding: 10px;
}
.infoboard {
    display: grid;
    grid-auto-rows: repeat(auto-fill, 300px);
    grid-template-columns: repeat(auto-fit, 400px);
    justify-content: center; /* グリッド全体を中央に寄せる設定 */
    row-gap: 20px;
    column-gap: 20px;
    padding: 0;
}
.infoboard > * {
    border: 1px solid #999;
    border-radius: 4px;
    list-style-type: none;
    padding: 0px;
}
.infoboard > * > .infohead {
    display: grid;

    grid-template-columns: 1fr 1fr;

    border-bottom: 1px solid #999;
    padding: 10px;
}
.infoboard > * > .infohead > .infodate {
    grid-row: 1;
    grid-column: 1;
}
.infoboard > * > .infohead > .infomark {
    grid-row: 1;
    grid-column: 2;
    text-align: right;
}
.infoboard > * > .infobody {
    padding: 10px;
    line-height: 1.5em;
}
.infoboard > * > .infobody > p {
    margin: 0px;
}
.infoboard > * > .infobody > p.small {
    font-size: xx-small;
    line-height: 1.5em;
    color: #ccc;
}
.infobody > ol,
.infobody > ul {
    margin-left: 0;
    padding-left: 1.2em;
}
.infobody > ul {
    list-style-type: unset;
}
.infobody > ol > li,
.infobody > ul > li {
    padding-bottom: 1ex;
}
.infoboard > *.hg1 {
}
.infoboard > *.hg2 {
    grid-row: span 2;
}
.infoboard > *.hg3 {
    grid-row: span 3;
}
.infoboard > *.wg2 {
    grid-column: span 2;
}
</style>
