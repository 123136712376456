<template>
    <div class="home">
        <ul class="homemenu">
            <li style="visibility:hidden"></li>
            <li v-if="hasTicket('op')">
                <router-link to="/oppo">
                    <span>案件管理</span>
                </router-link>
            </li>
            <li v-if="hasTicket('stg')">
                <router-link to="/stgt">
                    <span>売上達成率</span>
                </router-link>
            </li>
            <li v-if="hasTicket('cf')">
                <router-link to="/cft">
                    <span>日繰り表</span>
                </router-link>
            </li>
            <li v-if="hasTicket('areceivable')">
                <router-link to="/areceivable">
                    <span>売掛/買掛</span>
                </router-link>
            </li>
            <li v-if="hasTicket('smm')">
                <router-link to="/summarymonth">
                    <span>月別集計表</span>
                </router-link>
            </li>
            <li v-if="hasTicket('scd')">
                <router-link to="/salescostdetail">
                    <span>売上/原価</span>
                </router-link>
            </li>
            <li v-if="hasTicket('cust')">
                <router-link to="/cust">
                    <span>取引先</span>
                </router-link>
            </li>
            <li v-if="hasTicket('dept')">
                <router-link to="/dept">
                    <span>部署</span>
                </router-link>
            </li>
            <li v-if="hasTicket('emp')">
                <router-link to="/emp">
                    <span>社員</span>
                </router-link>
            </li>
            <li v-if="hasTicket('tgt')">
                <router-link to="/tgt">
                    <span>目標</span>
                </router-link>
            </li>
            <li v-if="hasTicket('acm')">
                <router-link to="/acm">
                    <span>締管理 α</span>
                </router-link>
            </li>
        </ul>
        <Dashboard />
        <Infoboard />
    </div>
</template>

<script>
// @ is an alias to /src
import Dashboard from "@/components/dashboard/Dashboard.vue";
import Infoboard from "@/components/dashboard/Infoboard.vue";
export default {
    name: "Home",
    components: {
        Dashboard,
        Infoboard
    }
};
</script>
<style scoped>
.homemenu {
    display: grid;
    grid-auto-rows: 50px;
    grid-template-columns: repeat(auto-fit, 120px);
    justify-content: center; /* グリッド全体を中央に寄せる設定 */
    row-gap: 20px;
    column-gap: 20px;
    padding: 10px 80px 40px 0px;
    margin: 0px;
}
.homemenu > * {
    display: block;
    border: 1px solid var(--border-color);
    border-radius: 4px;
    list-style-type: none;
    padding: 5px;
    justify-content: center;
    align-self: center;
    box-shadow: filter(--block-shadow);
}
.homemenu > * > a {
    display: block;
    text-align: center;
    align-self: center;
    padding: 10px;
    color: var(--main-text-color);
    text-decoration: none;
}
</style>
