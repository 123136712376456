<template>
    <div class="overlay">
        <div class="innerframe" ref="innerframe">
            <button @click="close" class="close-button">×</button>
            <div class="innerframe-header" @mousedown="onMouseDownHeader">
                <div class="title" v-if="title">
                    {{ title }}
                </div>
                <slot name="titlebar"></slot>
                <div class="options">
                    <span class="stepIndicator" v-if="steps">
                        <span v-for="(st, ix) in steps" :key="st.id">
                            <span v-if="ix > 0"> - </span>
                            <span
                                :class="['aStep', { currentStep: ix == step }]"
                                >{{ st }}</span
                            >
                        </span>
                    </span>

                    <span
                        class="functions"
                        v-if="
                            createButton ||
                                alterButton ||
                                changeButton ||
                                copyButton ||
                                removeButton
                        "
                    >
                        <span v-if="createButton" @click="onCreate">
                            <IconBase
                                iconName="作成"
                                width="12"
                                height="12"
                                :visibleName="true"
                                ><IconWrite
                            /></IconBase>
                        </span>
                        <span v-if="alterButton" @click="onAlter">
                            <IconBase
                                iconName="修正"
                                width="18"
                                height="18"
                                :visibleName="true"
                                ><IconPencil
                            /></IconBase>
                        </span>
                        <span v-if="changeButton" @click="onChange">
                            <IconBase
                                iconName="変更"
                                width="18"
                                height="18"
                                :visibleName="true"
                                ><IconWrite
                            /></IconBase>
                        </span>
                        <span v-if="copyButton" @click="onCopy">
                            <IconBase
                                iconName="複製"
                                width="18"
                                height="18"
                                :visibleName="true"
                                ><IconCopy
                            /></IconBase>
                        </span>
                        <span v-if="removeButton" @click="onRemove">
                            <IconBase
                                iconName="削除"
                                width="18"
                                height="18"
                                :visibleName="true"
                                ><IconEraser
                            /></IconBase>
                        </span>
                    </span>
                </div>
            </div>

            <div class="content">
                <slot :close="close" :step="changedStep" :steps="steps"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import IconBase from "@/components/icons/IconBase.vue";
import IconWrite from "@/components/icons/IconWrite.vue";
import IconPencil from "@/components/icons/IconPencil.vue";
import IconCopy from "@/components/icons/IconCopy.vue";
import IconEraser from "@/components/icons/IconEraser.vue";

export default {
    components: {
        IconBase,
        IconWrite,
        IconPencil,
        IconCopy,
        IconEraser
    },
    props: {
        title: String,
        steps: Array,
        createButton: Boolean,
        alterButton: Boolean,
        changeButton: Boolean,
        removeButton: Boolean,
        copyButton: Boolean
    },
    data: () => ({
        step: 0,
        isHMouseDown: false,
        hmousex0: 0,
        hmousey0: 0
    }),
    methods: {
        close() {
            this.$emit("close");
        },
        changedStep: function(val) {
            this.step = val;
        },
        onCreate() {
            this.$emit("clickCreate");
        },
        onChange() {
            this.$emit("clickChange");
        },
        onAlter() {
            this.$emit("clickAlter");
        },
        onCopy() {
            this.$emit("clickCopy");
        },
        onRemove() {
            this.$emit("clickRemove");
        },
        onMouseDownHeader: function(event) {
            let x = event.pageX;
            let y = event.pageY;
            let f = this.$refs.innerframe;
            let frect = f.getBoundingClientRect();
            let ftop = frect.top;
            let fleft = frect.left;
            f.style.top = ftop + "px";
            f.style.left = fleft + "px";
            this.isHMouseDown = true;
            this.hmousex0 = x;
            this.hmousey0 = y;
            window.addEventListener("mousemove", this.onMouseMoveHeader);
            window.addEventListener("mouseup", this.onMouseUpHeader);
        },
        onMouseMoveHeader: function(event) {
            if (this.isHMouseDown) {
                let x = event.pageX;
                let y = event.pageY;
                let f = this.$refs.innerframe;
                let frect = f.getBoundingClientRect();
                // let ftop = frect.top;
                // let fleft = frect.left;
                let dt = frect.top + y - this.hmousey0;
                let dl = frect.left + x - this.hmousex0;
                let dr = frect.right + x - this.hmousex0;
                const MINL = 100;
                const MAXL = window.innerWidth - 100;
                const MINT = 0;
                const MAXT = window.innerHeight - 50;
                if (dt < MINT) {
                    dt = MINT;
                }
                if (dt > MAXT) {
                    dt = MAXT;
                }
                if (dr < MINL) {
                    dl = frect.left;
                } else if (dl > MAXL) {
                    dl = MAXL;
                }

                f.style.top = dt + "px";
                f.style.left = dl + "px";

                this.hmousex0 = x;
                this.hmousey0 = y;
            }
        },
        onMouseUpHeader: function() {
            window.removeEventListener("mousemove", this.onMouseMoveHeader);
            window.removeEventListener("mouseup", this.onMouseUpHeader);
            this.isHMouseDown = false;
        }
    }
};
</script>

<style scoped>
.options {
    /* justify-self: end; */
    position: absolute;
    top: 5px;
    right: 50px;
}
.functions {
    font-size: 1rem;
    display: flex;
    color: #fff;
}
.functions > * {
    cursor: pointer;
    margin: 0 1rem;
}
.innerframe-header {
    /* display: grid;
    grid-template-columns: 1fr 1fr; */
    padding-right: 50px;
    position: relative;
}
.innerframe-header .title {
    justify-self: start;
}
.stepIndicator {
    justify-self: end;
    padding-right: 2rem;
}
.stepIndicator .aStep {
    font-size: 12px;
    color: #ccc;
    padding: 10px 10px 2px 10px;
}
.stepIndicator .currentStep {
    color: white;
    border-bottom: 2px solid white;
}
</style>
