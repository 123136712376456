<template>
    <div class="confirm-panel">
        <div class="confirm-message" v-if="isStep(0)">
            <div class="message-body">
                <div class="icon">
                    <IconBase width="48" height="48" v-slot="{ viewbox }">
                        <IconCaution
                            @viewbox="viewbox"
                            v-if="
                                icon == 'caution' ||
                                    icon == 'warn' ||
                                    icon == 'warning'
                            "
                        />
                        <IconQuestion @viewbox="viewbox" v-else />
                    </IconBase>
                </div>
                <div class="message">
                    <div
                        v-for="(msg, ix) in messages"
                        :key="ix"
                        :class="msg.class"
                    >
                        {{ msg.text }}
                    </div>
                </div>
                <div>
                    <slot></slot>
                </div>
            </div>
            <div class="control-box">
                <div>
                    <button @click="doCancel">キャンセル</button>
                </div>
                <div>
                    <button @click.once="doOk" :disabled="alwaysOk">
                        OK
                    </button>
                </div>
                <div></div>
            </div>
        </div>
        <div v-if="isStep(1)" class="complete">
            <div class="message">
                {{ complete }}
            </div>
            <div class="control-box">
                <div></div>
                <div>
                    <button @click="close">
                        閉じる
                    </button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconBase from "@/components/icons/IconBase.vue";
import IconCaution from "@/components/icons/IconCaution.vue";
import IconQuestion from "@/components/icons/IconQuestion.vue";

export default {
    name: "OpConfirm",
    props: {
        confirm: [String, Array],
        okfunction: Function,
        complete: String,
        completeAfterFunction: Function,
        icon: String
    },
    components: {
        IconBase,
        IconCaution,
        IconQuestion
    },
    data: function() {
        return {
            alwaysOk: false,
            currentStep: 0,
            messages: []
        };
    },
    methods: {
        doOk: async function() {
            this.alwaysOk = true;
            await this.okfunction(() => {
                this.$emit("after", true);
                this.setStep(1);
            });
        },
        doCancel: function() {
            this.$emit("fin");
        },
        close: function() {
            this.$emit("fin");
            // let a =  this.currentStep > 0;
            // this.$emit("after", a);
        },
        setStep: function(step) {
            if (this.currentStep != step) {
                this.currentStep = step;
                this.$emit("step", step);
                let wd = document.querySelector(".innerframe .content");
                if (wd) {
                    wd.scrollTo(0, 0);
                }
            }
        },
        isStep: function(step) {
            return this.currentStep == step;
        }
    },
    created: function() {
        if (this.confirm instanceof Array) {
            this.messages = [];
            for (let obj of this.confirm) {
                if (obj instanceof Object) {
                    this.messages.push(obj);
                } else {
                    this.messages.push({ text: obj });
                }
            }
        } else if (this.confirm instanceof Object) {
            this.messages = [this.confirm];
        } else {
            this.messages = [{ text: this.confirm }];
        }
    }
};
</script>

<style scoped>
.confirm-panel {
    position: relative;
    min-width: 450px;
    padding: 0;
}
.control-box {
    display: grid;
    /* border-top: 1px solid gray; */
    padding: 10px 0;
    grid-template-columns: 150px 1fr 150px;
    justify-items: center;
}
.control-box > div:first-child {
    justify-self: left;
}
.control-box > div:last-child {
    justify-self: right;
}

.message-body {
    display: flex;
}
.message {
    align-self: center;
}
.message > * {
    white-space: pre;
    min-height: 1rem;
}
.message .warn {
    color: red;
}

.icon {
    margin: 0;
    padding: 20px;
    align-self: center;
    justify-self: center;
}

/* --- complete --- */
.complete .message {
    font-size: 1.2rem;
    white-space: nowrap;
    padding: 20px 10px;
    text-align: center;
}
</style>
