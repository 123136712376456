<template>
    <div class="content dashboard-panel"></div>
</template>

<script>
// @ is an alias to /src
// import HelloWorld from "@/components/HelloWorld.vue";

export default {
    name: "Dashboard",
    components: {
        // HelloWorld
    },
    data: function() {
        return {};
    },
    methods: {},
    created: async function() {}
};
</script>

<style scoped>
.dashboard-panel {
    display: grid;
    /* grid-auto-rows: 300px; */
    grid-template-columns: repeat(auto-fit, 300px);
    justify-content: center; /* グリッド全体を中央に寄せる設定 */
    row-gap: 20px;
    column-gap: 20px;
}
</style>
