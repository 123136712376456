var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
        'form-field',
        'op-date-textfield',
        { 'op-date-textfield-readonly': _vm.readonly },
        { disabled: _vm.disabled }
    ]},[(_vm.label)?_c('label',{staticClass:"title",attrs:{"for":_vm.myid}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),(!_vm.readonly && _vm.required)?_c('div',{staticClass:"restrict"},[(_vm.required)?_c('div',{class:[
                'restrict-item',
                _vm.checkRequired ? 'checked-ok' : 'checked-error'
            ]},[_vm._v(" 必 ")]):_vm._e()]):_vm._e(),_c('div',{staticClass:"form-field-input"},[(_vm.readonly)?_c('span',{staticClass:"op-textfield-inner readonly"},[_vm._v(" "+_vm._s(_vm._f("date")(_vm.dateModel))+" ")]):_c('span',[_c('v-date-picker',{attrs:{"min-date":this.mindate,"max-date":this.maxdate,"update-on-input":false,"attributes":_vm.attributes},scopedSlots:_vm._u([{key:"default",fn:function({ inputValue, togglePopover, inputEvents }){return [_c('div',[_c('button',{staticClass:"calendarbutton",attrs:{"tabindex":"-1"},on:{"click":function($event){return togglePopover({ placement: 'auto-start' })}}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","viewBox":"0 0 20 20","width":"20px"}},[_c('path',{attrs:{"d":"M1 4c0-1.1.9-2 2-2h14a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V4zm2 2v12h14V6H3zm2-6h2v2H5V0zm8 0h2v2h-2V0zM5 9h2v2H5V9zm0 4h2v2H5v-2zm4-4h2v2H9V9zm0 4h2v2H9v-2zm4-4h2v2h-2V9zm0 4h2v2h-2v-2z"}})])]),_c('input',_vm._g({ref:"input",staticClass:"op-textfield-inner",attrs:{"type":"text","id":_vm.myid,"autocomplete":"off","disabled":_vm.disabled},domProps:{"value":inputValue},on:{"change":_vm.oncheck}},inputEvents))])]}}]),model:{value:(_vm.dateModel),callback:function ($$v) {_vm.dateModel=$$v},expression:"dateModel"}}),_c('button',{staticClass:"op-textfield-clear-button",attrs:{"type":"button","tabindex":"-1"},on:{"click":function($event){_vm.dateModel = null}}},[_vm._v(" × ")])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }