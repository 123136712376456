import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
    {
        path: "/",
        name: "Home",
        component: Home
    },
    // {
    //     path: "/",
    //     name: "Home",
    //     redirect: { name: "op" }
    // },
    {
        path: "/cust",
        name: "cust",
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import(
                /* webpackChunkName: "cust" */ "../views/customer/CustomerList.vue"
            )
    },
    {
        path: "/dept",
        name: "dept",
        component: () =>
            import(
                /* webpackChunkName: "dept" */ "../views/department/DepartmentList.vue"
            )
    },
    {
        path: "/emp",
        name: "emp",
        component: () =>
            import(
                /* webpackChunkName: "emp" */ "../views/employee/EmployeeList.vue"
            )
    },
    {
        path: "/oppo",
        name: "op",
        component: () =>
            import(
                /* webpackChunkName: "op" */ "../views/opportunity/Retrieval.vue"
            ),
        meta: { scrollY: 0 }
    },
    {
        path: "/oppo/:opid/:soid?/:poid?",
        name: "Opportunity",
        props: true,
        component: () =>
            import(
                /* webpackChunkName: "oppo" */ "../views/opportunity/Opportunity.vue"
            )
    },
    {
        path: "/stgt",
        name: "stgt",
        component: () =>
            import(
                /* webpackChunkName: "stgt" */ "../views/salestarget/SalesTarget.vue"
            )
    },
    {
        path: "/cft",
        name: "cft",
        component: () =>
            import(
                /* webpackChunkName: "cft" */ "../views/cashflow/CashFlow.vue"
            )
    },
    {
        path: "/tgt",
        name: "tgt",
        component: () =>
            import(
                /* webpackChunkName: "tgt" */ "../views/salestarget/TargetList.vue"
            )
    },
    {
        path: "/areceivable",
        name: "areceivable",
        component: () =>
            import(
                /* webpackChunkName: "areceivable" */ "../views/areceivable/Areceivable.vue"
            )
    },
    {
        path: "/acm",
        name: "acm",
        component: () =>
            import(
                /* webpackChunkName: "acm" */ "../views/aclosing/AclosingList.vue"
            )
    },
    {
        path: "/summarymonth",
        name: "summarymonth",
        component: () =>
            import(
                /* webpackChunkName: "summarymonth" */ "../views/summarymonth/SummaryMonth.vue"
            )
    },
    {
        path: "/salescostdetail",
        name: "salescostdetail",
        component: () =>
            import(
                /* webpackChunkName: "salescostdetail" */ "../views/salescostdetail/SalesCostDetail.vue"
            )
    },
    {
        path: "/ldr",
        name: "ldr",
        component: () =>
            import(
                /* webpackChunkName: "tgt" */ "../views/loader/DataLoaderHome.vue"
            )
    }
];

const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior(to, from, savedPosition) {
        if (to.meta) {
            if (to.meta.scrollY !== undefined) {
                let y = 0;
                if (savedPosition) {
                    y = savedPosition.y;
                }
                to.meta.scrollY = y;
            }
        }
        if (savedPosition) {
            return savedPosition;
        } else {
            return { x: 0, y: 0 };
        }
    },
    routes
});

export default router;
