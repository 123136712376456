<template>
    <div id="app" @click.capture="onClickHandler">
        <nav id="nav">
            <!--
            <div>{{ $store.state.userProfile.username }}</div>
            <div>{{ $store.state.subject }}</div>
            -->
            <input type="checkbox" id="nav-checkbox" v-model="navCheck" />
            <div id="nav-container">
                <div class="burger" @click="hideNav"></div>
                <label class="button" tabindex="0" for="nav-checkbox">
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                    <span class="icon-bar"></span>
                </label>
                <div class="site-title">
                    <img alt="mop logo" src="./assets/logo.svg" width="56px" />
                    <div class="mop-stage-ura">{{ this.stage }}</div>
                    <div class="mop-stage">{{ this.stage }}</div>
                </div>
                <div id="nav-content" tabindex="0">
                    <ul>
                        <li>
                            <router-link to="/">
                                <span @click="hideNav">Home</span>
                            </router-link>
                        </li>
                        <li v-if="hasTicket('op')">
                            <router-link to="/oppo">
                                <span @click="hideNav">案件管理</span>
                            </router-link>
                        </li>
                        <li v-if="hasTicket('stg')">
                            <router-link to="/stgt">
                                <span @click="hideNav">売上達成率</span>
                            </router-link>
                        </li>
                        <li v-if="hasTicket('cf')">
                            <router-link to="/cft">
                                <span @click="hideNav">日繰り表</span>
                            </router-link>
                        </li>
                        <li v-if="hasTicket('areceivable')">
                            <router-link to="/areceivable">
                                <span @click="hideNav">売掛/買掛</span>
                            </router-link>
                        </li>
                        <li v-if="hasTicket('smm')">
                            <router-link to="/summarymonth">
                                <span @click="hideNav">月別集計表</span>
                            </router-link>
                        </li>
                        <li v-if="hasTicket('scd')">
                            <router-link to="/salescostdetail">
                                <span @click="hideNav">売上/原価</span>
                            </router-link>
                        </li>
                    </ul>

                    <ul>
                        <li v-if="hasTicket('cust')">
                            <router-link to="/cust">
                                <span @click="hideNav">取引先</span>
                            </router-link>
                        </li>
                        <li v-if="hasTicket('dept')">
                            <router-link to="/dept">
                                <span @click="hideNav">部署</span>
                            </router-link>
                        </li>
                        <li v-if="hasTicket('emp')">
                            <router-link to="/emp">
                                <span @click="hideNav">社員</span>
                            </router-link>
                        </li>
                        <li v-if="hasTicket('tgt')">
                            <router-link to="/tgt">
                                <span @click="hideNav">目標</span>
                            </router-link>
                        </li>
                        <li v-if="hasTicket('acm')">
                            <router-link to="/acm">
                                <span @click="hideNav">締管理 α</span>
                            </router-link>
                        </li>
                        <!-- <li v-if="hasTicket('ldr')">
                            <router-link to="/ldr">
                                <span @click="hideNav">データロードα </span>
                            </router-link>
                        </li> -->
                    </ul>
                </div>
            </div>
        </nav>

        <nav id="rnav">
            <!--
            <div>{{ $store.state.userProfile.username }}</div>
            <div>{{ $store.state.subject }}</div>
            -->
            <input type="checkbox" id="rnav-checkbox" v-model="rnavCheck" />
            <div id="rnav-container">
                <div class="burger" @click="hideRnav"></div>
                <label tabindex="0" for="rnav-checkbox">
                    <div class="button2">
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                        <span class="icon-bar"></span>
                    </div>
                    <div class="button1">
                        <img v-if="hasPhoto()" :src="getPhoto()" width="24" />
                        <IconBase
                            v-else
                            :iconName="$store.state.userProfile.firstName"
                            viewBox="0 0 512 512"
                            width="24"
                            height="24"
                            ><IconHuman
                        /></IconBase>
                    </div>
                </label>
                <div id="rnav-content" tabindex="0">
                    <ul>
                        <li>
                            {{ $store.state.userProfile.lastName }}
                            {{ $store.state.userProfile.firstName }}
                        </li>
                        <li>{{ $store.state.userProfile.username }}</li>

                        <!-- <li>{{ $store.state.userinfo.roles }}</li> -->
                        <li>
                            <button @click.prevent="signout">
                                サインアウト
                            </button>
                        </li>
                        <li class="block">
                            <op-checkbox
                                label="ダークモードα"
                                v-model="$store.state.mopTheme.isDark"
                            ></op-checkbox>
                            <small>（お試し）</small>
                        </li>
                        <li>
                            <div class="mopversion">
                                mop version. {{ this.version }}
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <div class="main" id="main">
            <div v-if="hasRole()">
                <router-view />
            </div>
            <div class="norole" v-else>
                権限がありません。 管理者へ問い合わせてください。
            </div>
        </div>

        <footer></footer>

        <OpModal
            v-if="$store.state.confirmDialog.show"
            :title="$store.state.confirmDialog.title"
            @close="$store.dispatch('initConfirm')"
            v-slot="{ close, step }"
            :steps="['確認', '完了']"
        >
            <OpConfirm
                :confirm="$store.state.confirmDialog.confirm"
                :complete="$store.state.confirmDialog.complete"
                :okfunction="$store.state.confirmDialog.okfunction"
                :icon="$store.state.confirmDialog.icon"
                @after="afterConfirm"
                @fin="close"
                @step="step"
            ></OpConfirm>
        </OpModal>

        <OpMessageModal
            v-if="$store.state.messageModal.show"
            :title="$store.state.messageModal.title"
            @close="onCloseMessageModal"
        >
            {{ $store.state.messageModal.message }}
        </OpMessageModal>
    </div>
</template>
<script>
import OpMessageModal from "@/components/OpMessageModal.vue";
import IconBase from "@/components/icons/IconBase.vue";
import IconHuman from "@/components/icons/IconHuman.vue";
import OpCheckbox from "@/components/OpCheckbox.vue";
import OpModal from "@/components/OpModal.vue";
import OpConfirm from "@/components/OpConfirm.vue";

export default {
    name: "App",
    components: {
        OpMessageModal,
        IconBase,
        IconHuman,
        OpCheckbox,
        OpConfirm,
        OpModal
    },
    data: function() {
        return {
            navCheck: false,
            rnavCheck: false,
            showUsermenu: false,
            userinfoClickEvent: null,
            usermenuItems: [
                {
                    name: "サインアウト",
                    doAction: this.signout
                }
            ],
            version: require("../package.json").version,
            stage: process.env.VUE_APP_MOP_STAGE
        };
    },
    watch: {
        "router.currentRoute": function() {
            this.hideNav();
        }
    },
    methods: {
        hideNav: function() {
            this.navCheck = false;
        },
        hideRnav: function() {
            this.rnavCheck = false;
        },
        onClickHandler: function(event) {
            this.$store.commit("clickedTarget", event.target);
        },
        onCloseMessageModal: function(event) {
            this.$store.state.messageModal.show = false;
            let func = this.$store.state.messageModal.onclose;
            if (func != null) {
                this.$store.state.messageModal.onclose = null;
                func(event);
            }
        },
        hasPhoto: function() {
            const str = sessionStorage.getItem("hasUserPhoto");
            return str == "true";
        },
        getPhoto: function() {
            return sessionStorage.getItem("userPhoto");
        },
        afterConfirm: function(ok) {
            this.$store.state.confirmDialog.afterfunction(ok);
        }
    },
    created: function() {
        this.loadTheme();
        this.readArrayOneData(
            this.makeurl("nbd", "days", { from: "all" }),
            "nonbusiness_date",
            data => this.$store.commit("bankNonBusinessDays", data)
        );
        this.readDataToArray(this.makeurl("trt"), list =>
            this.$store.commit("taxratelist", list)
        );
    }
};
////   font-family Avenir, Helvetica, Arial, sans-serif
</script>
<style lang="stylus">
#app
    font-family: "Helvetica Neue", Arial, "Hiragino Kaku Gothic ProN", "Hiragino Sans",
    Meiryo,
    sans-serif;
  -webkit-font-smoothing antialiased
  -moz-osx-font-smoothing grayscale
</style>
<style scoped>
#nav-container {
    position: fixed;
    height: 100vh;
    width: 100%;
    pointer-events: none;
    z-index: 11;
}
#nav-container .burger {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: calc(100% - 0px);
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    background: #000;
}
/* #nav-container:focus-within .burger { */
#nav-checkbox:checked + #nav-container .burger {
    visibility: visible;
    opacity: 0.6;
    pointer-events: all;
}
#nav-container * {
    visibility: visible;
}

#nav-container .button {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    -webkit-appearance: none;
    border: 0;
    background: var(--button-bg-color);
    border-radius: 20px;
    height: 40px;
    width: 40px;
    padding: 10px;
    cursor: pointer;
    pointer-events: auto;
    margin: 5px 15px;
    touch-action: manipulation;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: background-color 0.1s;
    box-shadow: var(--block-shadow);
}
#nav-checkbox:checked + #nav-container .button {
    background-color: transparent;
}
#nav-container .site-title {
    position: absolute;
    top: 5px;
    left: 50px;
    z-index: 1;
    display: block;
    /* background-color: var(--main-context-bg-color); */
    padding: 10px 15px;
    border-radius: 20px;
    height: 40px;
    transition: background-color 1s;
}
#nav-container .site-title .mop-stage {
    position: absolute;
    top: 30px;
    left: 32px;
    color: #000;
}
#nav-container .site-title .mop-stage-ura {
    position: absolute;
    top: 30px;
    left: 32px;
    color: var(--main-context-bg-color);
    text-shadow: 0px 0px 2px #fff;
}

#nav-checkbox:checked + #nav-container .site-title {
    background-color: transparent;
    transition: background-color 0.1s;
}

#nav-checkbox {
    display: absolute;
    top: 0;
    z-index: 22;
    display: none;
}
#nav-container .icon-bar {
    display: block;
    width: 100%;
    height: 3px;
    background: var(--main-text-color);
    transition: 0.3s;
}
#nav-container .icon-bar + .icon-bar {
    margin-top: 5px;
}
/*
#nav-container:focus-within .button {
    pointer-events: none;
}
*/
/* #nav-container:focus-within .icon-bar:nth-of-type(1) { */
#nav-checkbox:checked + #nav-container .icon-bar:nth-of-type(1) {
    transform: translate3d(0, 8px, 0) rotate(45deg);
}
/* #nav-container:focus-within .icon-bar:nth-of-type(2) { */
#nav-checkbox:checked + #nav-container .icon-bar:nth-of-type(2) {
    opacity: 0;
}
/* #nav-container:focus-within .icon-bar:nth-of-type(3) { */
#nav-checkbox:checked + #nav-container .icon-bar:nth-of-type(3) {
    transform: translate3d(0, -8px, 0) rotate(-45deg);
}

#nav-content {
    margin-top: 0px;
    padding: 30px 20px 0px 20px;
    width: 90%;
    max-width: 300px;
    position: absolute;
    top: 0;
    left: 0;
    height: calc(100% - 0px);
    background: var(--main-context-bg-color);
    pointer-events: auto;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transform: translateX(-100%);
    transition: transform 0.3s;
    will-change: transform;
    contain: paint;
}

#nav-content ul {
    /* height: 100%; */
    display: flex;
    flex-direction: column;
    list-style-type: none;
}

#nav-content li {
    margin-top: 5px;
}

#nav-content li a span {
    display: inline-block;
    transition: color 0.1s;
    padding: 10px 5px;
}

#nav-content li:not(.small) + .small {
    margin-top: auto;
}

/* #nav-container:focus-within #nav-content { */
#nav-checkbox:checked + #nav-container #nav-content {
    transform: none;
}
/* -- ★★★★★★★★★★★★★★★★★ -- */
#rnav-container {
    position: fixed;
    height: 100vh;
    width: 100%;
    pointer-events: none;
    z-index: 18;
}
#rnav-container .burger {
    position: absolute;
    top: 0px;
    left: 0;
    width: 100%;
    height: calc(100% - 0px);
    visibility: hidden;
    opacity: 0;
    transition: 0.3s;
    background: #000;
}
/* #nav-container:focus-within .burger { */
#rnav-checkbox:checked + #rnav-container .burger {
    visibility: visible;
    opacity: 0.6;
    pointer-events: all;
}
#rnav-container * {
    visibility: visible;
}

#rnav-container .button1 {
    position: absolute;
    top: 0px;
    right: 0px;
    z-index: 2;
    -webkit-appearance: none;
    border: 1px solid var(--border-color);
    background: var(--main-context-bg-color);
    /* border-radius: 20px; */
    height: 30px;
    width: auto;
    max-width: 120px;
    overflow: hidden;
    padding: 2px;
    cursor: pointer;
    pointer-events: auto;
    margin: 10px 5px 5px 5px;
    touch-action: manipulation;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: background-color 0.1s;
    transition: transform 0.3s;
    box-shadow: var(--block-shadow);
}

#rnav-container .button2 {
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 2;
    -webkit-appearance: none;
    border: 0;
    background: var(--main-context-bg-color);
    border-radius: 20px;
    height: 40px;
    width: 40px;
    padding: 10px;
    cursor: pointer;
    pointer-events: auto;
    margin: 5px 5px;
    touch-action: manipulation;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transition: background-color 0.1s;
    opacity: 0;
}
#rnav-checkbox:checked + #rnav-container .button2 {
    background-color: transparent;
    opacity: 1;
}

#rnav-checkbox {
    display: absolute;
    top: 0;
    right: 0;
    z-index: 22;
    display: none;
}
#rnav-container .icon-bar {
    display: block;
    width: 100%;
    height: 3px;
    background: var(--main-text-color);
    transition: 0.3s;
}
#rnav-container .icon-bar + .icon-bar {
    margin-top: 5px;
}
/*
#nav-container:focus-within .button {
    pointer-events: none;
}
*/
#rnav-checkbox:checked + #rnav-container .button1 {
    transform: translateX(-40px);
    transition: transform 0.3s;
}
#rnav-checkbox + #rnav-container .icon-bar:nth-of-type(1) {
    transform: translate3d(0, 8px, 0) rotate(45deg) scale(0);
    opacity: 1;
}
#rnav-checkbox + #rnav-container .icon-bar:nth-of-type(2) {
    opacity: 0;
}
#rnav-checkbox + #rnav-container .icon-bar:nth-of-type(3) {
    transform: translate3d(0, -8px, 0) rotate(-45deg) scale(0);
    opacity: 1;
}

#rnav-checkbox:checked + #rnav-container .icon-bar:nth-of-type(1) {
    transform: translate3d(0, 8px, 0) rotate(45deg) scale(1);
    opacity: 1;
}

#rnav-checkbox:checked + #rnav-container .icon-bar:nth-of-type(2) {
    opacity: 0;
}

#rnav-checkbox:checked + #rnav-container .icon-bar:nth-of-type(3) {
    transform: translate3d(0, -8px, 0) rotate(-45deg) scale(1);
    opacity: 1;
}

#rnav-content {
    margin-top: 0px;
    padding: 30px 20px 0px 20px;
    width: 90%;
    max-width: 300px;
    position: absolute;
    top: 0;
    right: 0;
    height: calc(100% - 0px);
    background: var(--main-context-bg-color);
    pointer-events: auto;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    transform: translateX(100%);
    transition: transform 0.3s;
    will-change: transform;
    contain: paint;
}

#rnav-content ul {
    height: 100%;
    display: flex;
    flex-direction: column;
    list-style-type: none;
    margin: 10px 5px 0 10px;
    padding: 0;
}

#rnav-content li {
    margin-top: 5px;
    padding-bottom: 1em;
}
#rnav-content li.block {
    border-top: 1px solid var(--border-weak-color);
    padding-top: 20px;
}

#rnav-content li a span {
    display: inline-block;
    transition: color 0.1s;
    padding: 10px 5px;
}

#rnav-content li:not(.small) + .small {
    margin-top: auto;
}

/* #nav-container:focus-within #nav-content { */
#rnav-checkbox:checked + #rnav-container #rnav-content {
    transform: none;
}

.st0 {
    fill: #4b4b4b;
}
.norole {
    padding: 4rem;
    text-align: center;
}
.mopversion {
    font-size: 12px;
    padding-top: 40px;
    text-align: right;
    color: var(--text-weak-color);
}
</style>
