import Vue from "vue";
import DateUtils from "./DateUtils";
import Utils from "./Utils";

Vue.filter("date", function(value) {
    if (!value) return "";
    return DateUtils.formatDate(value);
});
Vue.filter("dateJp", function(value) {
    if (!value) return "";
    return DateUtils.formatDateJp(value);
});
Vue.filter("yearOfDate", function(value) {
    if (!value) return "";
    let ret = value;
    if (value instanceof Date) {
        ret = value.getFullYear();
    }
    return ret;
});
Vue.filter("MonthAndDayOfDate", function(value) {
    if (!value) return "";
    return DateUtils.formatDateMonthAndDay(value);
});
Vue.filter("md", function(value) {
    if (!value) return "";
    return DateUtils.formatDateMonthAndDay(value);
});
Vue.filter("ymjp", function(value) {
    if (!value) return "";
    return DateUtils.formatYearMonthJp(value);
});

function convDispAmount(value, minussign, dft, decimals) {
    let ret = "";
    if (value == undefined || value == null) {
        if (dft === undefined) {
            value = "0";
        }
        return dft;
    }
    if (typeof value == "string") {
        value = value.replace(/\s|,/g, "");
    }
    let num = new Number(value);
    if (isNaN(num)) {
        ret = "-";
    } else {
        if (num < 0) {
            ret = minussign;
        }
        ret += Math.abs(num).toLocaleString();
        if (decimals != undefined && !isNaN(decimals) && decimals > 0) {
            let ix = ret.indexOf(".");
            if (ix < 0) {
                ix = ret.length;
                ret += ".";
            }
            let dd = decimals - (ret.length - ix - 1);
            for (let i = 0; i < dd; i++) {
                ret += "0";
            }
        }
    }

    return ret;
}

Vue.filter("amount", function(value, dft, decimals) {
    return convDispAmount(value, " - ", dft, decimals);
});
Vue.filter("amountf", function(value, dft, decimals) {
    return convDispAmount(value, "▲", dft, decimals);
});
Vue.filter("amountdiff", function(value) {
    let ret = "";
    if (value == undefined || value == null) return "0";
    let num = new Number(value);
    if (isNaN(num)) {
        ret = "-";
    } else {
        if (num < 0) {
            ret = " - ";
        } else if (num > 0) {
            ret = " + ";
        } else {
            ret = " ± ";
        }
        ret += Math.abs(num).toLocaleString();
    }
    return ret;
});

Vue.filter("rate", function(value, prec, mtp, mkr) {
    let ret = "";
    if (!prec) {
        prec = 0;
    }
    if (value == undefined || value == null) return "0";
    let num = new Number(value);
    if (isNaN(num)) {
        ret = "-";
    } else {
        if (mtp === undefined || mtp === null) {
            mtp = 100;
        }
        ret = num * mtp;
        ret = Utils.round(ret, prec);
        if (mkr === undefined || mkr === null) {
            mkr = " %";
        }
        ret += mkr;
    }
    return ret;
});
Vue.filter("ratediff", function(value, prec) {
    let ret = "";
    if (!prec) {
        prec = 0;
    }
    if (value == undefined || value == null) return "0";
    let num = new Number(value);
    if (isNaN(num)) {
        ret = "-";
    } else {
        if (num < 0) {
            ret = " - ";
        } else if (num > 0) {
            ret = " + ";
        } else {
            ret = " ± ";
        }
        let r = num * 100;
        r = Utils.round(r, prec);
        ret += r + " %";
    }
    return ret;
});
Vue.filter("abs", function(value) {
    let ret = "";
    if (value == undefined || value == null) return "0";
    let num = new Number(value);
    if (isNaN(num)) {
        ret = value;
    } else {
        ret = Math.abs(num);
    }
    return ret;
});
Vue.filter("datetime", function(value) {
    return DateUtils.formatDatetime(value);
});
Vue.filter("timestamp", function(value) {
    return DateUtils.formatDatetime(value);
});
Vue.filter("flag", function(value) {
    let ret = "";
    if (!value) return ret;
    return "✔";
});
