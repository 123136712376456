import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        mopTheme: {
            isDark: false,
            name: null
        },
        keycloak: null,
        userProfile: null,
        subject: null,
        messageModal: {
            show: false,
            title: null,
            message: null,
            onclose: null
        },
        confirmDialog: {
            show: false,
            title: "",
            confirm: "",
            complete: "",
            okfunction: function() {},
            afterfunction: function() {}
        },
        requestCancelKeys: new Map(),
        company: null,
        userinfo: null,
        clickedTarget: null,
        retrivequery: null,
        idseq: 0,
        employeelist: { timestamp: null, list: null },
        bankNonBusinessDays: null,
        taxratelist: null,
        departmentlist: null,
        banklist: null,
        customerlist: {
            delayTimerMap: new Map(),
            custWordDict: new Map(),
            custWordResMap: new Map(),
            custWordHitMap: new Map(),
            cacheExpire: 0
        },
        opportunityState: {
            opportunityEditor: {
                model: null,
                show: false,
                op: null,
                updated: null,
                mode: null,
                moveto: function() {}
            },
            salesorderEditor: {
                model: null,
                show: false,
                op: null,
                updated: null,
                mode: null
            },
            purchaseorderEditor: {
                model: null,
                show: false,
                so: null,
                updated: null,
                mode: null
            },
            costofsaleslistEditor: {
                data: null,
                show: false,
                po: null,
                op: null,
                poid: null,
                updated: null,
                mode: null
            },
            saleslistEditor: {
                data: null,
                show: false,
                so: null,
                op: null,
                soid: null,
                updated: null,
                mode: null
            },
            opportunityLog: {
                show: false,
                model: null
            },
            salesorderLog: {
                show: false,
                model: null
            },
            salesLog: {
                show: false,
                model: null
            },
            costofsalesLog: {
                show: false,
                model: null
            },
            purchaseorderLog: {
                show: false,
                model: null
            },
            confirmDialog: {
                show: false,
                title: "",
                confirm: "",
                complete: "",
                okfunction: function() {},
                afterfunction: function() {}
            },
            refreshNumber: 0
        }
    },
    mutations: {
        keycloak(state, keycloak) {
            state.keycloak = keycloak;
        },
        userProfile(state, userProfile) {
            state.userProfile = userProfile;
        },
        subject(state, subject) {
            state.subject = subject;
        },
        company(state, company) {
            state.company = company;
        },
        userinfo(state, userinfo) {
            state.userinfo = userinfo;
        },
        retrivequery(state, query) {
            state.retrivequery = query;
        },
        inclementIdseq(state) {
            if (state.idseq >= Number.MAX_SAFE_INTEGER) {
                state.idseq = 1;
            } else {
                state.idseq++;
            }
        },
        employeelist(state, employeelist) {
            state.employeelist = employeelist;
        },
        opportunityState(state, obj) {
            state.opportunityState = obj;
        },
        clickedTarget(state, obj) {
            state.clickedTarget = obj;
        },
        bankNonBusinessDays(state, obj) {
            state.bankNonBusinessDays = obj;
        },
        taxratelist(state, obj) {
            state.taxratelist = obj;
        },
        confirmDialog(state, obj) {
            state.confirmDialog = obj;
        }
    },
    actions: {
        clearUserinfo(context) {
            // context.commit("userProfile", {});
            // context.commit("subject", {});
            // context.commit("username", "");
            context.commit("retrivequery", { words: "", listdiv: "op" });

            // context.state.keycloak.logout({ redirectUri: url });
            // context.state.keycloak.logout();
        },
        clearCustomerList(context) {
            let t = context.state.customerlist;
            t.delayTimerMap = new Map();
            t.custWordDict = new Map();
            t.custWordResMap = new Map();
            t.custWordHitMap = new Map();
        },
        initOppoState(context) {
            let stt = context.state.opportunityState;
            for (let sttprop in stt) {
                let st = stt[sttprop];
                for (let stprop in st) {
                    if (stprop == "show") {
                        st[stprop] = false;
                    }
                }
            }
            context.commit("opportunityState", stt);
        },
        initOppoStateConfirm(context) {
            const st = context.state.opportunityState;
            const stt = st.confirmDialog;
            stt.show = false;
            stt.title = "";
            stt.confirm = "";
            stt.complete = "";
            stt.icon = null;
            stt.okfunction = function() {};
            stt.afterfunction = function() {};
            context.commit("opportunityState", st);
        },
        initConfirm(context) {
            const stt = context.state.confirmDialog;
            stt.show = false;
            stt.title = "";
            stt.confirm = "";
            stt.complete = "";
            stt.icon = null;
            stt.okfunction = function() {};
            stt.afterfunction = function() {};
            context.commit("confirmDialog", stt);
        }
    },
    modules: {}
});
