<template>
    <div :class="['form-field', { disabled: disabled }]" :style="blockStyle">
        <label class="title" v-if="label" :for="myid">{{ label }}</label>
        <div class="restrict" v-if="visibleRestrict()">
            <div
                :class="[
                    'restrict-item',
                    checkRequired ? 'checked-ok' : 'checked-error'
                ]"
                v-if="required"
            >
                必
            </div>
            <div
                v-if="regexPattern"
                :class="[
                    'restrict-item',
                    checkPattern ? 'checked-ok' : 'checked-error'
                ]"
            >
                {{ this.patternMsg }}
            </div>
            <div
                v-if="fixlength > 0"
                :class="[
                    'restrict-item',
                    checkTextLength ? 'checked-ok' : 'checked-error'
                ]"
            >
                {{ fixlength }}文字
            </div>
            <div
                v-else-if="minlength > 1"
                :class="[
                    'restrict-item',
                    checkTextLength ? 'checked-ok' : 'checked-error'
                ]"
            >
                {{ minlength }} 文字以上
            </div>
        </div>
        <div :style="fieldStyle">
            <div class="form-field-input op-textfield">
                <div v-if="readonly" class="readonly-field">
                    {{ textModel }}
                </div>
                <textarea
                    v-else-if="type == 'textarea'"
                    v-model="textModel"
                    :id="myid"
                    @change="inputChange"
                    class="op-textfield-inner"
                    @blur="blurInput"
                    :disabled="disabled"
                    ref="input"
                    v-on="inputListeners"
                    :style="inputStyle"
                >
                </textarea>
                <input
                    v-else
                    class="op-textfield-inner"
                    type="text"
                    @change="inputChange"
                    v-model="textModel"
                    :maxlength="getMaxlength()"
                    :id="myid"
                    @blur="blurInput"
                    :disabled="disabled"
                    ref="input"
                    v-on="inputListeners"
                />
                <button
                    v-if="!readonly"
                    type="button"
                    :class="[
                        'op-textfield-clear-button',
                        { 'textarea-clear-button': type == 'textarea' }
                    ]"
                    @click="clear()"
                    :disabled="disabled"
                    tabindex="-1"
                >
                    ×
                </button>
            </div>
            <div v-if="!readonly" style="white-space:pre-wrap; padding;0;">
                <div
                    :class="[
                        'charscounter',
                        checkTextLength ? 'checked-ok' : 'checked-error'
                    ]"
                >
                    文字数
                    {{ remining }}
                    <span v-if="maxlength > 0"> ／ {{ maxlength }} </span>
                </div>
                <span class="fieldnote">
                    {{ note }}
                </span>
            </div>
            <div></div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OpTextField",
    model: {
        prop: "inputModel",
        event: "input"
    },
    data: function() {
        return {
            textModel: this.inputModel,
            errors: new Set(),
            validflag: true,
            regexPattern: null,
            patternMsg: "",
            myid: this.id,
            fieldStyle: {
                width: "100%"
            },
            blockStyle: {
                maxWidth: this.getInitMaxwidth(),
                width: "100%"
            },
            myBlur: null
        };
    },
    props: {
        inputModel: String,
        required: Boolean,
        fixlength: { default: 0 },
        maxlength: { default: 0 },
        minlength: { default: 0 },
        maxwidth: String,
        pattern: String,
        patternMessage: String,
        extraCheck: Function,
        name: String,
        label: String,
        id: String,
        type: String,
        halfOnly: Boolean,
        lpad: String,
        rpad: String,
        blur: Function,
        disabled: Boolean,
        note: String,
        readonly: Boolean,
        inputStyle: String
    },
    watch: {
        validflag: function(value) {
            if (this.name) {
                this.$emit("changed-valid", this.name, value);
            }
        },
        disabled: function(value) {
            if (value) {
                this.validflag = true;
            } else {
                this._checkRequired();
                this._checkTextLength();
                this._initCheckPattern();
                this._checkPattern();
            }
        },
        inputModel: function(val) {
            this.textModel = val;
        }
    },
    methods: {
        visibleRestrict: function() {
            let ret =
                !this.readonly &&
                (this.required ||
                    this.regexPattern ||
                    this.fixlength > 0 ||
                    this.minlength > 1);
            return ret;
        },
        blurInput: function() {
            if (this.fixlength > 0 && this.textModel) {
                let v = "";
                let mx = this.fixlength - this.textModel.length;
                if (mx > 0) {
                    if (this.lpad) {
                        for (let i = 0; i < mx; i++) {
                            v += this.lpad;
                        }
                        v += this.textModel;
                        this.textModel = v.slice(-this.fixlength);
                    } else if (this.rpad) {
                        for (let i = 0; i < mx; i++) {
                            v += this.rpad;
                        }
                        v = this.textModel + v;
                        this.textModel = v.slice(0, this.length);
                    }
                }
            }
            if (this.myBlur) {
                this.textModel = this.myBlur(this.textModel);
            }
            if (this.blur) {
                this.textModel = this.blur(this.textModel);
            }
            this.$emit("input", this.textModel);
        },
        getMaxlength: function() {
            let len = 0;
            if (this.fixlength) {
                len = this.fixlength;
            } else if (this.maxlength) {
                len = this.maxlength;
            }
            return len;
        },
        getInitMaxwidth: function() {
            let half = this._initCheckPattern();
            let ret = "";
            if (this.maxwidth) {
                ret = this.maxwidth;
            } else {
                let len = 0;
                if (this.fixlength) {
                    len = this.fixlength;
                } else if (this.maxlength) {
                    len = this.maxlength;
                }
                if (len > 0) {
                    if (half) {
                        ret = len * 1.2 + "ex";
                    } else {
                        ret = len * 1.2 + "em";
                    }
                    ret = "calc(" + ret + " + 80px)";
                }
            }
            return ret;
        },
        inputChange: function() {
            this.$emit("input", this.textModel);
        },
        settingErrors: function(name, isvalid) {
            let cols = this.errors;
            if (isvalid) {
                if (cols.has(name)) {
                    cols.delete(name);
                }
            } else {
                if (!cols.has(name)) {
                    cols.add(name);
                }
            }
            this.validflag = cols.size < 1;
        },
        clear: function() {
            if (this.textModel !== null && this.textModel !== "") {
                this.textModel = null;
                this.inputChange();
            }
        },
        _checkRequired() {
            let ret = true;
            if (this.required) {
                ret =
                    this.textModel != null && this.textModel.trim().length > 0;
            }
            this.settingErrors("required", ret);
            return ret;
        },
        _checkTextLength() {
            let ret = true;
            if (this.textModel != null) {
                let tlen = this.textModel.trim().length;
                if (this.fixlength > 0) {
                    ret = tlen == this.fixlength;
                } else {
                    if (this.maxlength > 0 && tlen > this.maxlength) {
                        ret = false;
                    }
                    if (ret && tlen < this.minlength) {
                        ret = false;
                    }
                }
            }
            this.settingErrors("length", ret);
            return ret;
        },
        _initCheckPattern() {
            let ret = false;
            let tp = this.pattern;
            if (!tp) {
                ret = this.halfOnly;
                if (ret) {
                    tp = "half";
                }
            }
            if (tp) {
                if (tp == "half") {
                    this.regexPattern = /^[\x20-\x7e]*$/;
                    this.patternMsg = "半角"; //"半角のみ";
                    ret = true;
                } else if (tp.startsWith("re:")) {
                    this.regexPattern = new RegExp(tp.substring(3));
                    this.patternMsg = this.patternMessage;
                    ret = this.halfOnly;
                } else {
                    let mmm = "";
                    let ptn = "";
                    if (tp.indexOf("a") > -1) {
                        ptn += "a-z";
                        mmm += "a";
                    }
                    if (tp.indexOf("A") > -1) {
                        ptn += "A-Z";
                        mmm += "A";
                    }
                    if (tp.indexOf("9") > -1) {
                        ptn += "0-9";
                        mmm += "9";
                    }
                    if (ptn != "") {
                        ret = true;
                        this.regexPattern = new RegExp("^[" + ptn + "]*$");
                        let msg = "";
                        let cnvf = 0;
                        switch (mmm) {
                            case "a":
                                msg = "半角[a]"; //"半角英子文字のみ";
                                cnvf = 1;
                                break;
                            case "A":
                                msg = "半角[A]"; //"半角英大文字のみ";
                                cnvf = 2;
                                break;
                            case "9":
                                msg = "半角[1]"; //"半角数字のみ";
                                break;
                            case "aA":
                                msg = "半角[aA]"; //"半角英字のみ";
                                break;
                            case "a9":
                                msg = "半角[1a]"; //"半角英数小文字のみ";
                                cnvf = 1;
                                break;
                            case "A9":
                                msg = "半角[1A]"; //"半角英数大文字のみ";
                                cnvf = 2;
                                break;
                            case "aA9":
                                msg = "半角[1aA]"; //"半角英数字のみ";
                                break;
                        }
                        this.patternMsg = msg;

                        if (cnvf > 0) {
                            this.myBlur = val => {
                                let ret = val;
                                if (val) {
                                    if (cnvf == 1) {
                                        ret = val.toLowerCase();
                                    } else if (cnvf == 2) {
                                        ret = val.toUpperCase();
                                    }
                                }
                                return ret;
                            };
                        }
                    }
                }
            }
            return ret;
        },
        _checkPattern() {
            let ret = true;
            if (this.regexPattern) {
                if (this.textModel != null) {
                    ret = this.regexPattern.test(this.textModel);
                }
            }
            this.settingErrors("pattern", ret);
            return ret;
        },
        focus: function() {
            this.$refs.input.focus();
        }
    },
    mounted: function() {
        this._checkRequired();
        this._checkTextLength();
        this._initCheckPattern();
        this._checkPattern();
        this.$emit("changed-valid", this.name, this.validflag);
    },
    computed: {
        checkRequired() {
            return this._checkRequired();
        },
        checkTextLength() {
            return this._checkTextLength();
        },
        checkPattern() {
            return this._checkPattern();
        },
        remining: function() {
            let tlen = 0;
            if (this.textModel != null) {
                tlen = this.textModel.trim().length;
            }
            return tlen;
        },
        inputListeners: function() {
            var vm = this;
            // `Object.assign` が複数のオブジェクトを一つの新しいオブジェクトにマージします
            return Object.assign(
                {},
                // 親からの全てのリスナを追加します
                this.$listeners,
                // そしてカスタムリスナを追加したり
                // すでに存在するリスナの振る舞いを変えることができます
                {
                    // こうすることでコンポーネントが v-model と動作します
                    input: function(event) {
                        vm.$emit("input", event.target.value);
                    }
                }
            );
        }
    },
    created: function() {
        if (!this.myid) {
            this.$store.commit("inclementIdseq");
            this.myid = "oppo2020" + this.$store.state.idseq;
        }
    }
};
</script>

<style scoped>
.textarea-clear-button {
    right: 20px;
}
textarea.op-textfield-inner {
    overflow-y: scroll;
    padding-right: 24px;
}
</style>
