<template>
    <div class="overlay">
        <div class="innerframe">
            <button @click="close" class="close-button">×</button>
            <div class="innerframe-header">
                <div class="title" v-if="title">
                    {{ title }}
                </div>
            </div>
            <div class="content">
                <slot></slot>
                <div class="control-box">
                    <button @click="close">OK</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        title: String,
        steps: Array
    },
    data: () => ({
        step: 0
    }),
    methods: {
        close() {
            this.$emit("close");
        },
        changedStep: function(val) {
            this.step = val;
        }
    }
};
</script>

<style scoped>
.innerframe-header {
    display: grid;
    min-height: 1rem;
    grid-template-columns: 1fr 1fr;
}
.innerframe-header .title {
    justify-self: start;
}
.control-box {
    padding: 10px;
    text-align: center;
}
</style>
