<template>
    <div :class="['form-field', { disabled: disabled }]">
        <div class="title" v-if="label">{{ label }}</div>
        <div class="restrict" v-if="required">
            <div
                v-if="required"
                :class="[
                    'restrict-item',
                    checkRequired ? 'checked-ok' : 'checked-error'
                ]"
            >
                必
            </div>
        </div>
        <div class="form-field-input radio-group">
            <div
                class="radio-item"
                v-for="(div, ix) in divlist"
                :key="div.divcode"
            >
                <input
                    type="radio"
                    :value="div.divcode"
                    @change="inputChange"
                    @click="radioClick(div)"
                    @keyup.n="radioClick(div)"
                    v-model="dataModel"
                    :name="name"
                    :id="myid + ix"
                    :disabled="disabled || readonly"
                />
                <label
                    :for="myid + ix"
                    :class="
                        disabled || (readonly && dataModel != div.divcode)
                            ? 'disabled'
                            : ''
                    "
                >
                    <span v-if="divViewType == 'status'" class="statusValue">
                        {{ div.divcode }}
                        <div class="statusName">
                            {{ div.contentlong }}
                        </div>
                    </span>
                    <span v-else>
                        {{ div.contentlong }}
                    </span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OpDivSelector",
    model: {
        prop: "radioModel",
        event: "input"
    },
    props: {
        radioModel: [String, Number],
        divlist: Object,
        name: String,
        required: Boolean,
        label: String,
        id: String,
        disabled: Boolean,
        readonly: Boolean,
        divViewType: String
    },
    data: function() {
        return {
            dataModel: this.sanitizeModel(this.radioModel),
            validflag: false,
            myid: this.id
        };
    },
    methods: {
        inputChange: function() {
            this.$emit("input", this.dataModel);
        },
        sanitizeModel: function(model) {
            let ret = null;
            for (const div in this.divlist) {
                if (model == div) {
                    ret = model;
                    break;
                }
            }
            return ret;
        },
        _checkRequired() {
            let ret =
                !this.required ||
                (this.dataModel != null && this.dataModel != undefined);
            this.validflag = ret;
            return ret;
        },
        radioClick(div) {
            if (this.dataModel == div.divcode) {
                this.dataModel = null;
                this.$emit("input", null);
            }
        }
    },
    watch: {
        validflag: function(value) {
            if (this.name) {
                this.$emit("changed-valid", this.name, value);
            }
        },
        required: function() {
            this._checkRequired();
        }
    },
    computed: {
        checkRequired() {
            return this._checkRequired();
        }
    },
    mounted: function() {
        this._checkRequired();
        this.$emit("changed-valid", this.name, this.validflag);
    },
    created: function() {
        if (!this.myid) {
            this.$store.commit("inclementIdseq");
            this.myid = "oppo2020" + this.$store.state.idseq;
        }
    }
};
</script>
