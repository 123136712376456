export default class {
    static formatDate(date) {
        let ret = date;
        let v = date;
        if (!(v instanceof Date) && typeof v == "string") {
            v = new Date(v);
        }
        if (v instanceof Date) {
            ret =
                v.getFullYear() +
                "/" +
                ("0" + (v.getMonth() + 1)).slice(-2) +
                "/" +
                ("0" + v.getDate()).slice(-2);
        }
        return ret;
    }

    static formatDateJp(date) {
        let ret = date;
        let v = date;
        if (!(v instanceof Date) && typeof v == "string") {
            v = new Date(v);
        }
        if (v instanceof Date) {
            ret =
                v.getFullYear() +
                "年" +
                ("0" + (v.getMonth() + 1)).slice(-2) +
                "月" +
                ("0" + v.getDate()).slice(-2) +
                "日";
        }
        return ret;
    }

    static formatYearMonthJp(date) {
        let ret = date;
        let v = date;
        if (!(v instanceof Date) && typeof v == "string") {
            v = new Date(v);
        }
        if (v instanceof Date) {
            ret =
                v.getFullYear() +
                "年" +
                ("0" + (v.getMonth() + 1)).slice(-2) +
                "月";
        }
        return ret;
    }

    static formatDateMonthAndDay(date) {
        let ret = date;
        if (date instanceof Date) {
            ret =
                ("0" + (date.getMonth() + 1)).slice(-2) +
                "/" +
                ("0" + date.getDate()).slice(-2);
        }
        return ret;
    }

    static formatDatetime(date, noseconds) {
        let ret = "";
        let v = date;
        if (!(v instanceof Date) && typeof v == "string") {
            v = new Date(v);
        }
        if (v instanceof Date) {
            ret =
                v.getFullYear() +
                "/" +
                ("0" + (v.getMonth() + 1)).slice(-2) +
                "/" +
                ("0" + v.getDate()).slice(-2) +
                " " +
                ("0" + v.getHours()).slice(-2) +
                ":" +
                ("0" + v.getMinutes()).slice(-2);
            if (noseconds !== true) {
                ret += ":" + ("0" + v.getSeconds()).slice(-2);
            }
        }
        return ret;
    }

    static isDatetimeString(str) {
        let ret = false;
        if (typeof str == "string") {
            ret = /^[0-9]{4}[./-][0-9]{1,2}[./-][0-9]{1,2}[T ][0-9]{1,2}:[0-9]{1,2}(:[0-9]{1,2})?$/.test(
                str
            );
        }
        return ret;
    }

    static searchCommentTimestamp(str) {
        let ret = -1;
        if (typeof str == "string") {
            ret = str.search(
                /\(([^()]+ )?[0-9]{4}[./-][0-9]{1,2}[./-][0-9]{1,2}[T ][0-9]{1,2}:[0-9]{1,2}(:[0-9]{1,2})?\)$/
            );
        }
        return ret;
    }
    static toString8(date) {
        let ret = "";
        let v = date;
        if (!(v instanceof Date) && typeof v == "string") {
            v = new Date(v);
        } else if (v === undefined || v === null) {
            v = new Date();
        }
        if (v instanceof Date) {
            ret =
                v.getFullYear() +
                ("0" + (v.getMonth() + 1)).slice(-2) +
                ("0" + v.getDate()).slice(-2);
        }
        return ret;
    }
    static toYm6(date) {
        let ret = "";
        let v = date;
        if (!(v instanceof Date) && typeof v == "string") {
            v = new Date(v);
        }
        if (v instanceof Date) {
            ret = v.getFullYear() + ("0" + (v.getMonth() + 1)).slice(-2);
        }
        return ret;
    }
    static toRequestDate(date) {
        return this.toString8(date);
    }
    static toRequestRangeDate(startdate, enddate) {
        let ret = "";
        if (startdate) {
            ret += this.toRequestDate(startdate);
        }
        ret += "-";
        if (enddate) {
            ret += this.toRequestDate(enddate);
        }
        if (ret == "-") {
            ret = null;
        }
        return ret;
    }

    static getYearMonthList(startDate, endDate) {
        let ret = [];
        let sdate = new Date(startDate.getTime());

        let mx = 0;
        mx = (endDate.getFullYear() - sdate.getFullYear()) * 12;
        mx += endDate.getMonth() - sdate.getMonth() + 1;

        for (let i = 0; i < mx; i++) {
            // ret.push({
            //     year: sdate.getFullYear(),
            //     month: sdate.getMonth() + 1
            // });
            ret.push(this.toYm6(sdate));
            sdate.setMonth(sdate.getMonth() + 1);
        }
        return ret;
    }

    static getFiscalRangeDate(company, fiscalyear, perioddiv) {
        let smd = company.fiscalyear_start_monthdate;
        let strs = fiscalyear + "-" + smd.slice(0, 2) + "-" + smd.slice(2, 4);
        let sdate = new Date(strs);
        let edate = new Date(strs);
        let ofs = 12;
        if (perioddiv == 1) {
            ofs = 6;
        } else if (perioddiv == 2) {
            ofs = 6;
            sdate.setMonth(sdate.getMonth() + 6);
        }
        edate.setMonth(sdate.getMonth() + ofs);
        edate.setDate(sdate.getDate() - 1);
        return { start: sdate, end: edate };
    }

    static getRequestFiscalRangeDate(company, fiscalyear, perioddiv) {
        let smd = company.fiscalyear_start_monthdate;
        let strs = fiscalyear + "-" + smd.slice(0, 2) + "-" + smd.slice(2, 4);
        let sdate = new Date(strs);
        let edate = new Date(strs);
        let ofs = 12;
        if (perioddiv == 1) {
            ofs = 6;
        } else if (perioddiv == 2) {
            ofs = 6;
            sdate.setMonth(sdate.getMonth() + 6);
        }
        edate.setMonth(sdate.getMonth() + ofs);
        edate.setDate(sdate.getDate() - 1);
        return this.toRequestRangeDate(sdate, edate);
    }

    static getFiscalYearAndHalfsignToday(company) {
        return this.getFiscalYearAndHalfsign(company, new Date());
    }

    static getFiscalYearAndHalfsign(company, referenceDate) {
        let co = company;
        let fsmd = co.fiscalyear_start_monthdate;
        let today = new Date(referenceDate.getTime());
        today.setHours(0);
        today.setMinutes(0);
        today.setSeconds(0);
        today.setMilliseconds(0);

        let fmon = parseInt(fsmd.slice(0, 2));
        let fdat = parseInt(fsmd.slice(2, 4));

        let fyear = today.getFullYear();
        let tmon = today.getMonth() + 1;
        if (tmon < fmon || (tmon == fmon && today.getDate() < fdat)) {
            fyear -= 1;
        }

        let tsday = new Date();
        tsday.setFullYear(fyear);
        tsday.setMonth(fmon - 1 + 6);
        tsday.setDate(fdat);
        tsday.setHours(0);
        tsday.setMinutes(0);
        tsday.setSeconds(0);
        tsday.setMilliseconds(0);

        let hsgn = 2;
        if (today < tsday) {
            hsgn = 1;
        }
        return {
            fiscalyear: fyear,
            halfsign: hsgn
        };
    }

    static formatFiscalYear(year, establishmentYear, halfsign) {
        let ret = year + "年度";
        if (establishmentYear) {
            // let startyear = company.establishment_year;
            ret += "（第" + (year - establishmentYear + 1) + "期）";
        }
        if (halfsign !== undefined && halfsign !== null) {
            if (halfsign == 0) {
                ret += "通期";
            } else if (halfsign == 1) {
                ret += "上期";
            } else if (halfsign == 2) {
                ret += "下期";
            }
        }
        return ret;
    }

    static getNowDate() {
        let ret = new Date();
        ret = new Date(
            ret.getFullYear(),
            ret.getMonth(),
            ret.getDate(),
            0,
            0,
            0,
            0
        );
        return ret;
    }

    static createDate(year, month, date) {
        let ret = new Date(year, month - 1, date, 0, 0, 0, 0);
        return ret;
    }

    static cloneDate(date) {
        let ret = new Date(
            date.getFullYear(),
            date.getMonth(),
            date.getDate(),
            0,
            0,
            0,
            0
        );
        return ret;
    }

    static convertToDateConvenience(stringDate) {
        let ret = null;
        if (stringDate) {
            const arg1 = stringDate;
            if (arg1 == "t") {
                ret = this.getNowDate();
            } else if (arg1 == "e") {
                let dt = this.getNowDate();
                dt.setMonth(dt.getMonth() + 1);
                dt.setDate(0);
                ret = dt;
            } else {
                let now = this.getNowDate();
                let y = "" + now.getFullYear();
                let m = "" + (now.getMonth() + 1);
                let d = "" + now.getDate();
                let docheck = true;
                if (arg1.match(/^\d{1,8}$/)) {
                    const sz = arg1.length;
                    switch (sz) {
                        case 1:
                        case 2:
                            d = arg1;
                            break;
                        case 4:
                            m = arg1.substring(0, 2);
                            d = arg1.substring(2);
                            break;
                        case 6:
                            y = "20" + arg1.substring(0, 2);
                            m = arg1.substring(2, 4);
                            d = arg1.substring(4);
                            break;
                        case 8:
                            y = arg1.substring(0, 4);
                            m = arg1.substring(4, 6);
                            d = arg1.substring(6);
                            break;
                        default:
                    }
                } else {
                    const res = arg1.match(
                        /^(((?<year>\d{1,4})[ 年/.-])?(?<month>\d{1,2})[ 月/.-])?(?<date>(?:\d{1,2}|e))日?$/u
                    );
                    if (res) {
                        let sy = res.groups.year;
                        if (sy) {
                            switch (sy.length) {
                                case 1:
                                    sy = "200" + sy;
                                    break;
                                case 2:
                                    sy = "20" + sy;
                                    break;
                                case 3:
                                    sy = "2" + sy;
                                    break;
                                default:
                            }
                            y = sy;
                        }
                        let sm = res.groups.month;
                        if (sm) {
                            m = sm;
                        }
                        d = res.groups.date;
                        if (d == "e") {
                            m++;
                            d = 0;
                            docheck = false;
                        }
                    }
                }
                const iy = Number.parseInt(y, 10);
                const im = Number.parseInt(m, 10) - 1;
                const id = Number.parseInt(d, 10);
                ret = new Date(iy, im, id, 0, 0, 0, 0);

                if (
                    docheck &&
                    (ret.getDate() != id ||
                        ret.getMonth() != im ||
                        ret.getFullYear() != iy)
                ) {
                    ret = null;
                }
            }
        }
        return ret;
    }
}
