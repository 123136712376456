export default class {
    static round(num, pre) {
        var shift = function(num, pre, reverseShift) {
            if (reverseShift) {
                pre = -pre;
            }
            var numArray = ("" + num).split("e");
            return +(
                numArray[0] +
                "e" +
                (numArray[1] ? +numArray[1] + pre : pre)
            );
        };
        return shift(Math.round(shift(num, pre, false)), pre, true);
    }
}
