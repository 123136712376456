var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content"},[_c('h3',[_vm._v("お知らせ")]),_c('ul',{staticClass:"infoboard"},[_vm._m(0),_vm._m(1),_vm._m(2),_vm._m(3),_vm._m(4),_vm._m(5),_vm._m(6),_vm._m(7),_c('li',{staticClass:"hg1"},[_vm._m(8),_c('div',{staticClass:"infobody"},[_vm._v(" お試し機能として、右サイドパネルに「"),_c('op-checkbox',{attrs:{"label":"ダークモードα"},model:{value:(_vm.$store.state.mopTheme.isDark),callback:function ($$v) {_vm.$set(_vm.$store.state.mopTheme, "isDark", $$v)},expression:"$store.state.mopTheme.isDark"}}),_vm._v("」を用意しました。気になる方はお試しください。 ")],1)]),_vm._m(9),_vm._m(10),_vm._m(11),_vm._m(12),_vm._m(13),_vm._m(14),_vm._m(15),_vm._m(16),_vm._m(17),_vm._m(18),_vm._m(19),_vm._m(20),_vm._m(21),_vm._m(22),_vm._m(23)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hg1"},[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2024.09.12")]),_c('div',{staticClass:"infomark"},[_vm._v("バグ修正")])]),_c('div',{staticClass:"infobody"},[_c('ul',[_c('li',[_vm._v(" 受注/発注の編集で売上/原価が１件しかない場合にも「...複数存在...」のアラートが表示されるバグを修正しました。 ")]),_c('li',[_vm._v(" 案件管理の「案件」で事業部を指定しても何もヒットしないバグを修正しました。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hg2"},[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2024.09.09")]),_c('div',{staticClass:"infomark"},[_vm._v("機能改善")])]),_c('div',{staticClass:"infobody"},[_c('ul',[_c('li',[_vm._v(" 案件管理にある検索条件:案件担当部署にて事業部での選択も可能にしました。 ")]),_c('li',[_vm._v(" 部署および取引先の入力において有効期限内の値のみ選択できるように修正しました。 ")]),_c('li',[_vm._v(" 受注および発注の入力において売上日または計上日を自動で変更されない場合に警告メッセージを出すようにしました。 ")]),_c('li',[_vm._v(" 取引先の選択にて文字を入力後にEnterキーを押した際、候補が複数ある場合はドロップダウンリストを表示するように変更しました。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hg1"},[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2024.04.18")]),_c('div',{staticClass:"infomark"},[_vm._v("機能変更")])]),_c('div',{staticClass:"infobody"},[_vm._v(" 「売上達成率」にて「履歴と比較表示」をする際に表示中に期のデータのみを対象とするように変更。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hg2"},[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2024.02.11")]),_c('div',{staticClass:"infomark"},[_vm._v("機能改善")])]),_c('div',{staticClass:"infobody"},[_c('ul',[_c('li',[_vm._v(" 「案件管理」のデフォルトの検索条件を「通期」に変更しました。 ")]),_c('li',[_vm._v(" 「案件管理」のフィルターにを「検収日と売上日の値」と「納品日と計上日の値」を追加しました。 ")]),_c('li',[_vm._v(" 「売上達成率」の詳細「受注件名」に取引先名の表示を追加しました。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hg1"},[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2023.06.25")]),_c('div',{staticClass:"infomark"},[_vm._v("機能改善")])]),_c('div',{staticClass:"infobody"},[_vm._v(" 「売上達成率」にて「履歴と比較表示」をした状態で明細を表示した際の表示速度を改善しました。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hg1"},[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2023.05.12")]),_c('div',{staticClass:"infomark"},[_vm._v("仕様バグ修正")])]),_c('div',{staticClass:"infobody"},[_vm._v(" 「売上達成率」にて他年度データと比較した際、表示年度に存在していない部署も表示するように修正 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hg1"},[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2022.10.03")]),_c('div',{staticClass:"infomark"},[_vm._v("機能追加")])]),_c('div',{staticClass:"infobody"},[_vm._v(" データ出力機能「売上/原価詳細」を追加しました。（管理ユーザーのみ） ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hg1"},[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2022.4.25")]),_c('div',{staticClass:"infomark"},[_vm._v("更新")])]),_c('div',{staticClass:"infobody"},[_vm._v(" 認証・認可で使用しているKeycloakのバージョンを更新しました。 そのため、ログイン画面のデザイン等が変更されました。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2022.4.25")]),_c('div',{staticClass:"infomark"},[_vm._v("お試し機能追加")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hg1"},[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2022.4.25")]),_c('div',{staticClass:"infomark"},[_vm._v("仕様変更")])]),_c('div',{staticClass:"infobody"},[_vm._v(" [案件詳細] "),_c('ul',[_c('li',[_vm._v(" 受注ヘッダに発注金額を表示するようにしました。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hg2"},[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2022.4.25")]),_c('div',{staticClass:"infomark"},[_vm._v("仕様変更")])]),_c('div',{staticClass:"infobody"},[_c('p',[_vm._v(" 売掛回収の消費税計算時に数円ズレる場合があったので、消費税率の持ち方を変更（売上→受注、原価→発注）しました。 それに伴い、[案件詳細] の表示および [日繰り表] の計算方法も変更しました。 ")]),_vm._v(" [案件詳細] "),_c('ul',[_c('li',[_vm._v(" 売上からに消費税率を削除 ")]),_c('li',[_vm._v(" 受注にに消費税率を追加 ")]),_c('li',[_vm._v(" 原価からに消費税率を削除 ")]),_c('li',[_vm._v(" 発注にに消費税率を追加 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hg1"},[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2022.4.25")]),_c('div',{staticClass:"infomark"},[_vm._v("仕様変更")])]),_c('div',{staticClass:"infobody"},[_vm._v(" [取引先マスタ] "),_c('ul',[_c('li',[_vm._v(" 入金サイトと支払サイトに0ヵ月を設定できるようにしました。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hg1"},[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2022.4.25")]),_c('div',{staticClass:"infomark"},[_vm._v("バグ修正")])]),_c('div',{staticClass:"infobody"},[_vm._v(" [売上達成率] "),_c('ul',[_c('li',[_vm._v(" 他の期と比較に際に通期／上期／下期の変更が出来ないバグを修正しました。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hg1"},[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2022.4.25")]),_c('div',{staticClass:"infomark"},[_vm._v("機能改善")])]),_c('div',{staticClass:"infobody"},[_vm._v(" 一時処理の負荷が上がりアクセス不能になることがあったので、安定稼働するように、一部SQLのチューニング、Indexの追加、プロセス数の追加を行いました。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hg1"},[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2021.5.17")]),_c('div',{staticClass:"infomark"},[_vm._v("機能追加")])]),_c('div',{staticClass:"infobody"},[_vm._v(" 案件・受注・発注の削除機能を追加しました ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hg1"},[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2021.4.22")]),_c('div',{staticClass:"infomark"},[_vm._v("機能追加")])]),_c('div',{staticClass:"infobody"},[_vm._v(" 「月別集計表」（管理者のみ）を追加しました ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hg2"},[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2021.4.22")]),_c('div',{staticClass:"infomark"},[_vm._v("機能追加")])]),_c('div',{staticClass:"infobody"},[_vm._v(" [案件検索] "),_c('ul',[_c('li',[_vm._v(" （受注）売上が複数件ある受注と、売上額と受注額が不一致の受注にマークを表示するようにしました ")]),_c('li',[_vm._v(" （発注）原価が複数件ある発注と、原価額と発注額が不一致の発注にマークを表示するようにしました ")]),_c('li',[_vm._v("ページ送り機能を実装しました")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hg2"},[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2021.4.22")]),_c('div',{staticClass:"infomark"},[_vm._v("仕様変更")])]),_c('div',{staticClass:"infobody"},[_vm._v(" [案件検索] "),_c('ul',[_c('li',[_vm._v("一覧の表示項目を見直しました")])]),_vm._v(" [案件詳細] "),_c('ul',[_c('li',[_vm._v("表示項目および並び順を見直しました")]),_c('li',[_vm._v("少々見た目も変更しました")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hg1"},[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2021.4.22")]),_c('div',{staticClass:"infomark"},[_vm._v("バグ修正")])]),_c('div',{staticClass:"infobody"},[_vm._v(" [発注 編集／複製] "),_c('ul',[_c('li',[_vm._v("支払日が自動計算されないバグを修正しました。")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hg2"},[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2021.3.22")]),_c('div',{staticClass:"infomark"},[_vm._v("仕様変更")])]),_c('div',{staticClass:"infobody"},[_vm._v(" [案件詳細] "),_c('ul',[_c('li',[_vm._v(" 受注の受注金額および検収日を変更した際、売上が１件のみの場合に、その売上金額および計上日に変更した受注金額および検収日を自動で設定する機能を追加しました。 ")]),_c('li',[_vm._v(" 発注と同様に発注金額および納品日のを変更した際、原価が１件のみの場合に、その原価金額および計上日を変更した発注金額および納品日を自動で設定する機能を追加しました。 ")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2021.3.22")]),_c('div',{staticClass:"infomark"},[_vm._v("仕様変更")])]),_c('div',{staticClass:"infobody"},[_vm._v(" [案件一覧] 受注のない案件は、受注や発注に関する条件を無視して一覧に表示するようにし、受注ステータスのところにメッセージを表示するようにしました。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2021.3.22")]),_c('div',{staticClass:"infomark"},[_vm._v("バグ修正")])]),_c('div',{staticClass:"infobody"},[_vm._v(" [案件検索] 日付の範囲入力に関するバグを修正しました。 ")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hg1"},[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2021.3.8")]),_c('div',{staticClass:"infomark"},[_vm._v("仕様変更")])]),_c('div',{staticClass:"infobody hg1"},[_c('p',[_vm._v(" 案件管理の検索結果から詳細へ移動する際にクリックする範囲を「行全体」から「行番号のみ」へ変更しました。 ")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('li',{staticClass:"hg1"},[_c('div',{staticClass:"infohead"},[_c('div',{staticClass:"infodate"},[_vm._v("2021.3.8")]),_c('div',{staticClass:"infomark"},[_vm._v("試しに")])]),_c('div',{staticClass:"infobody hg1"},[_c('p',[_vm._v(" ホーム（トップ）ページに各機能へのリンクとお知らせを追加してみました。 ")]),_c('p',[_vm._v("単色ワイヤーフレームのべた書きです。")]),_c('p',{staticClass:"small"},[_vm._v(" 動作確認時にいちいちハンバーガーを押すのが面倒になってしまったのでメニューをトップページに乗せました。苦情は桂田まで。。 ")])])])
}]

export { render, staticRenderFns }