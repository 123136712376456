import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import * as Keycloak from "keycloak-js";
import VueLogger from "vuejs-logger";
import { SERVICE_BASEURL } from "./Functions";
import "./filters";
import VCalendar from "v-calendar";
import vueSmoothScroll from "vue-smooth-scroll";

Vue.config.productionTip = false;

const axios = require("axios");

// ------------------------------
let initOptions = {
    url: process.env.VUE_APP_MOP_AUTH_URL, // "http://xxxxxx/auth"
    realm: process.env.VUE_APP_MOP_AUTH_REALM, //"Demo",
    clientId: process.env.VUE_APP_MOP_AUTH_CLIENTID, //"oppo-app-demo-local",
    onLoad: "login-required",
    checkLoginIframe: false
};

const options = {
    isEnabled: true,
    logLevel: Vue.config.productionTip ? "error" : "debug",
    stringifyArguments: false,
    showLogLevel: true,
    showMethodName: true,
    separator: "|",
    showConsoleColors: true
};
Vue.use(VueLogger, options);
Vue.use(vueSmoothScroll);
Vue.use(VCalendar, {
    locales: {
        ja: {
            masks: {
                title: "YYYY / MM",
                L: "YYYY/MM/DD"
            }
        }
    },
    datePicker: {
        popover: {
            visibility: "none"
        }
    }
});

let ktoken = null;
let krtoken = null;
if ("sessionStorage" in window && window.sessionStorage !== null) {
    ktoken = sessionStorage.getItem("vue-token");
    krtoken = sessionStorage.getItem("vue-refresh-token");
}

let keycloak = Keycloak(initOptions);

keycloak
    .init({
        onLoad: initOptions.onLoad,
        checkLoginIframe: initOptions.checkLoginIframe,
        token: ktoken,
        refreshToken: krtoken
    })
    .then(auth => {
        if (!auth) {
            Vue.$log.info("Not Authenticated");
            window.location.reload();
        } else {
            Vue.$log.info("Authenticated");

            store.commit("keycloak", keycloak);
        }

        keycloak
            .loadUserProfile()
            .then(async function(profile) {
                axios.defaults.headers.common["Authorization"] =
                    "Bearer " + keycloak.token;
                store.commit("userProfile", profile);
                // for (let p in profile) {
                //     console.log("■■■ userProfile[" + p + "] = " + profile[p]);
                // }
                // for (let p in profile["attributes"]) {
                //     console.log(
                //         "■■■ userProfile[attributes][" +
                //             p +
                //             "] = " +
                //             profile["attributes"][p]
                //     );
                // }
                let emp = profile["attributes"]["employeeId"];

                let picturl = profile["attributes"]["picture"];
                let idptoken = profile["attributes"]["idptoken"];
                if (sessionStorage.getItem("hasUserPhoto") != "true") {
                    sessionStorage.setItem("hasUserPhoto", "false");
                    if (picturl && idptoken) {
                        await axios
                            .get(initOptions.url + idptoken)
                            .then(response => {
                                const msAccessToken =
                                    response.data.access_token;
                                axios
                                    .get(picturl, {
                                        responseType: "arraybuffer",
                                        headers: {
                                            Authorization:
                                                "Bearer " + msAccessToken
                                        }
                                    })
                                    .then(resp => {
                                        const prefix =
                                            "data:" +
                                            resp.headers["content-type"] +
                                            ";base64,";
                                        const base64 = new Buffer(
                                            resp.data,
                                            "binary"
                                        ).toString("base64");
                                        sessionStorage.setItem(
                                            "userPhoto",
                                            prefix + base64
                                        );
                                        sessionStorage.setItem(
                                            "hasUserPhoto",
                                            "true"
                                        );
                                    });
                            })
                            .catch(response =>
                                Vue.$log.info("!!!!!!!!!!" + response.data)
                            );
                    }
                }

                await Promise.all([
                    axios
                        .get(SERVICE_BASEURL + "/sys/subject")
                        .then(response =>
                            store.commit("subject", response.data)
                        )
                        .catch(response => store.commit("subject", response)),

                    axios
                        .get(SERVICE_BASEURL + "/sys/userinfo?emp=" + emp)
                        .then(response => {
                            store.commit("userinfo", response.data);
                        })
                        .catch(response => store.commit("userinfo", response)),

                    axios
                        .get(SERVICE_BASEURL + "/comp")
                        .then(response =>
                            store.commit("company", response.data)
                        )
                        .catch(response => store.commit("company", response))
                ]);
                //alert(JSON.stringify(profile, null, "  "))
                new Vue({
                    router,
                    store,
                    render: h => h(App)
                }).$mount("#app");

                // req.setRequestHeader('Accept', 'application/json');
                // req.setRequestHeader('Authorization', 'Bearer ' + keycloak.token);
            })
            .catch(() => {
                alert("Failed to load user profile");
            });

        // new Vue({
        //   router,
        //   store,
        //   render: h => h(App)
        // }).$mount("#app");

        if ("sessionStorage" in window && window.sessionStorage !== null) {
            sessionStorage.setItem("vue-token", keycloak.token);
            sessionStorage.setItem("vue-refresh-token", keycloak.refreshToken);
        }

        // localStorage.setItem("vue-token", keycloak.token);
        // localStorage.setItem("vue-refresh-token", keycloak.refreshToken);
        setInterval(() => {
            keycloak
                .updateToken(70)
                .then(refreshed => {
                    if (refreshed) {
                        Vue.$log.debug("Token refreshed" + refreshed);
                        axios.defaults.headers.common["Authorization"] =
                            "Bearer " + keycloak.token;
                        if (
                            "sessionStorage" in window &&
                            window.sessionStorage !== null
                        ) {
                            sessionStorage.setItem("vue-token", keycloak.token);
                            sessionStorage.setItem(
                                "vue-refresh-token",
                                keycloak.refreshToken
                            );
                        }
                    } else {
                        Vue.$log.warn(
                            "Token not refreshed, valid for " +
                                Math.round(
                                    keycloak.tokenParsed.exp +
                                        keycloak.timeSkew -
                                        new Date().getTime() / 1000
                                ) +
                                " seconds"
                        );
                    }
                })
                .catch(() => {
                    Vue.$log.error("Failed to refresh token");
                });
        }, 6000);
    })
    .catch(() => {
        Vue.$log.error("Authenticated Failed");
    });
