import Vue from "vue";
import OpTextField from "@/components/OpTextField.vue";
import OpDateTextField from "@/components/OpDateTextField.vue";
import OpDivSelector from "@/components/OpDivSelector.vue";
import AutoComplete from "@/components/AutoComplete.vue";
import OpSelect from "@/components/OpSelect.vue";
import OpModal from "@/components/OpModal.vue";
import DateUtils from "./DateUtils";
// import { publicPath } from "../vue.config";

const axios = require("axios");

export const SERVICE_BASEURL = process.env.VUE_APP_MOP_API_URL; //"http://localhost:5000"; //  `%{process.env.MOP_API_URL}`;
// export const SERVICE_BASEURL = "http://192.168.1.121:5000";

const terms = {
    record_date: "計上日",
    delivery_date: "納品日",
    acceptance_date: "検収日"
};
const CACHE_LIFE_MILLISEC = 30000;

Vue.mixin({
    data: function() {
        return {
            divs: [],
            deptlist: [],
            deptlisth: {},
            banklist: [],
            cancelTokenDict: {},
            requesting: false,
            department_all_for_list: [],
            department_all_for_list_at: 0
        };
    },
    components: {
        OpDivSelector,
        AutoComplete,
        OpTextField,
        OpDateTextField,
        OpSelect,
        OpModal
    },
    methods: {
        makeurl: function(...args) {
            let ret = SERVICE_BASEURL;
            let que = "";
            for (const a of args) {
                if (a instanceof Date) {
                    ret += "/" + encodeURIComponent(this.dateTo8Str(a));
                } else if (a != null && typeof a == "object") {
                    for (const b in a) {
                        if (b.endsWith("[]") && a[b] instanceof Array) {
                            for (const bb of a[b]) {
                                que +=
                                    "&" +
                                    encodeURIComponent(b) +
                                    "=" +
                                    encodeURIComponent(bb);
                            }
                        } else {
                            que +=
                                "&" +
                                encodeURIComponent(b) +
                                "=" +
                                encodeURIComponent(a[b]);
                        }
                    }
                } else if (a !== null && a !== undefined && a !== "") {
                    ret += "/" + encodeURIComponent(a);
                }
            }
            if (que != "") {
                ret += "?" + que.substring(1);
            }
            return ret;
        },
        loaddivs: function(...divnames) {
            let divlist = new Array();
            for (const div of divnames) {
                const divkey = "div_" + div;
                let divjson = sessionStorage.getItem(divkey);
                if (divjson == null) {
                    divlist.push(div);
                } else {
                    this.divs[div] = JSON.parse(divjson); // ★
                    if (Object.keys(this.divs[div]).length < 1) {
                        divlist.push(div);
                    }
                }
            }
            let qdiv = "";
            for (const div of divlist) {
                qdiv += "," + div;
            }
            if (qdiv != "") {
                qdiv = qdiv.substring(1);
                let url = this.makeurl("div", { divs: qdiv });
                return axios
                    .get(url)
                    .then(response => {
                        let rd = response.data;
                        for (const p in rd) {
                            let r = rd[p];
                            let val = this.headDataToObj(
                                r.header,
                                r.data,
                                obj => obj.divcode
                            );
                            this.divs[p] = val; // ★
                            sessionStorage.setItem(
                                "div_" + p,
                                JSON.stringify(val)
                            );
                        }
                    })
                    .catch(err => this.httpErrorHandler(err));
            }
        },
        // headDataToArray: function(header, data, keyfunc, convfunc) {
        //     const ret = [];
        //     this.mergeHeadDataToObj(ret, header, data, keyfunc, convfunc);
        //     return ret;
        // },
        headDataToObj: function(header, data, keyfunc, convfunc) {
            const ret = {};
            this.mergeHeadDataToObj(ret, header, data, keyfunc, convfunc);
            return ret;
        },
        mergeHeadDataToObj: function(source, header, data, keyfunc, convfunc) {
            let hs = header;
            let mx = hs.length;
            let ds = data;
            let dlen = ds.length;
            for (let j = 0; j < dlen; j++) {
                let obj = {};
                let d = ds[j];
                for (let i = 0; i < mx; i++) {
                    obj[hs[i]] = d[i];
                }
                if (convfunc != undefined) {
                    convfunc(obj);
                }
                let key;
                if (keyfunc != undefined && keyfunc != null) {
                    key = keyfunc(obj);
                } else {
                    key = j;
                }
                source[key] = obj;
            }
        },
        readData: function(url, successFunc, errfunc) {
            return axios
                .get(url)
                .then(response => {
                    const cdata = this.convertFromResponseData(response.data);
                    successFunc(cdata);
                })
                .catch(err => this.httpErrorHandler(err, errfunc));
        },
        readDataToObj: function(url, ddata, readRowFunc, errfunc) {
            // deplicated -> readDataToArray
            ddata.length = 0;
            return axios
                .get(url)
                .then(response => {
                    let hs = response.data.header;
                    let mx = hs.length;
                    let ds = response.data.data;
                    let dlen = ds.length;
                    for (let j = 0; j < dlen; j++) {
                        let obj = {};
                        let d = ds[j];
                        for (let i = 0; i < mx; i++) {
                            let key = hs[i];
                            obj[key] = this.convertFromRespnseValue(key, d[i]);
                        }
                        ddata.push(obj);
                        if (readRowFunc) {
                            readRowFunc(obj);
                        }
                    }
                })
                .catch(err => this.httpErrorHandler(err, errfunc));
        },
        cancelRequest: function(cancelkey) {
            const keymap = this.$store.state.requestCancelKeys;
            if (keymap.has(cancelkey)) {
                keymap.get(cancelkey).cancel();
                keymap.delete(cancelkey);
            }
        },
        readDataToArray: function(
            url,
            loadedFunc,
            readRowFunc,
            errfunc,
            cancelkey
        ) {
            let ddata = [];
            let options = {};
            if (cancelkey) {
                const keymap = this.$store.state.requestCancelKeys;
                if (keymap.has(cancelkey)) {
                    keymap.get(cancelkey).cancel();
                    keymap.delete(cancelkey);
                }
                const CancelToken = axios.CancelToken;
                let cs = CancelToken.source();
                keymap.set(cancelkey, cs);
                options["cancelToken"] = cs.token;
            }
            return axios
                .get(url, options)
                .then(response => {
                    let hs = response.data.header;
                    let mx = hs.length;
                    let ds = response.data.data;
                    let cnt = response.data.count;
                    let limit = response.data.limit;
                    let offset = response.data.offset;
                    let dlen = ds.length;
                    for (let j = 0; j < dlen; j++) {
                        let obj = {};
                        let d = ds[j];
                        for (let i = 0; i < mx; i++) {
                            let key = hs[i];
                            obj[key] = this.convertFromRespnseValue(key, d[i]);
                        }
                        ddata.push(obj);
                        if (readRowFunc) {
                            readRowFunc(obj);
                        }
                    }
                    loadedFunc(ddata, hs, cnt, limit, offset);
                })
                .catch(err => this.httpErrorHandler(err, errfunc));
        },
        readArrayOneData: function(url, key, loadedFunc, readRowFunc, errfunc) {
            let ddata = [];
            return axios
                .get(url)
                .then(response => {
                    let hs = response.data.header;
                    let mx = hs.length;
                    let ix = 0;
                    for (let i = 0; i < mx; i++) {
                        if (hs[i] == key) {
                            ix = i;
                            break;
                        }
                    }
                    let ds = response.data.data;
                    let dlen = ds.length;
                    for (let j = 0; j < dlen; j++) {
                        let d = ds[j];
                        let val = this.convertFromRespnseValue(key, d[ix]);
                        if (readRowFunc) {
                            val = readRowFunc(val);
                        }
                        ddata.push(val);
                    }
                    loadedFunc(ddata);
                })
                .catch(err => this.httpErrorHandler(err, errfunc));
        },
        convertFromRespnseValue: function(key, value) {
            let val = value;
            if (key.endsWith("_date") && typeof val == "string") {
                if (val.startsWith("9")) {
                    val = null;
                } else if (/^[0-9]{8}$/.test(val)) {
                    val =
                        val.substring(0, 4) +
                        "-" +
                        val.substring(4, 6) +
                        "-" +
                        val.substring(6, 8);
                    val = new Date(val);
                } else if (
                    /^[0-9]{4}[./-][0-9]{1,2}[./-][0-9]{1,2}$/.test(val)
                ) {
                    val = new Date(val);
                }
            } else if (key.endsWith("_flag")) {
                val = val != undefined && val != null && val != "0";
            }
            return val;
        },
        copyProperties: function(source, dest) {
            for (const p in source) {
                if (p in dest) {
                    dest[p] = source[p];
                }
            }
        },
        loaddeptlist: function(func, errfunc) {
            const deptlistkey = "deptlist";
            const expiredkey = deptlistkey + "-expired";
            let deptsjson = sessionStorage.getItem(deptlistkey);
            const expired = sessionStorage.getItem(expiredkey);
            if (deptsjson != null && expired != null && expired > Date.now()) {
                this.deptlist = JSON.parse(deptsjson); // ★
                if (func) {
                    func(this.deptlist);
                }
                return;
            }
            let rdate = DateUtils.toRequestDate(new Date());
            let url = this.makeurl("dept", { rdate: rdate });
            return axios
                .get(url)
                .then(response => {
                    let rd = response.data;
                    this.deptlist.length = 0;
                    this.mergeHeadDataToObj(this.deptlist, rd.header, rd.data);
                    this.initdeptlist();
                    sessionStorage.setItem(
                        deptlistkey,
                        JSON.stringify(this.deptlist)
                    );
                    sessionStorage.setItem(
                        deptlistkey + "-expired",
                        Date.now() + CACHE_LIFE_MILLISEC
                    );
                    if (func) {
                        func(this.deptlist);
                    }
                })
                .catch(err => this.httpErrorHandler(err, errfunc));
        },
        loaddeptlist_nocache: async function(ref_date, func, errfunc) {
            let rdate = DateUtils.toRequestDate(ref_date);
            let url = this.makeurl("dept", { rdate: rdate });
            return await axios
                .get(url)
                .then(response => {
                    let rd = response.data;
                    this.deptlist.length = 0;
                    this.mergeHeadDataToObj(this.deptlist, rd.header, rd.data);
                    this.initdeptlist();
                    if (func) {
                        func(this.deptlist);
                    }
                })
                .catch(err => this.httpErrorHandler(err, errfunc));
        },
        initdeptlist: function() {
            let pobj = [];
            for (let dept of this.deptlist) {
                if (dept.department_div == 0) {
                    pobj[dept.department_id] = dept;
                }
            }
            for (let dept of this.deptlist) {
                if (dept.department_div == 1) {
                    let p = pobj[dept.upper_department_id];
                    if (p) {
                        if (!p.children) {
                            p.children = [];
                        }
                        p.children.push(dept);
                    }
                }
            }
        },
        loadbanklist: function(errfunc) {
            const banklistkey = "banklist";
            let sjson = sessionStorage.getItem(banklistkey);
            if (sjson != null) {
                this.banklist = JSON.parse(sjson); // ★
                return;
            }
            let url = this.makeurl("bank");
            return axios
                .get(url)
                .then(response => {
                    let rd = response.data;
                    this.banklist.length = 0;
                    this.mergeHeadDataToObj(this.banklist, rd.header, rd.data);
                    sessionStorage.setItem(
                        banklistkey,
                        JSON.stringify(this.banklist)
                    );
                })
                .catch(err => this.httpErrorHandler(err, errfunc));
        },
        getBank: function(code) {
            let ret = null;
            if (this.banklist) {
                for (let bk of this.banklist) {
                    if (bk.bank_code == code) {
                        ret = bk;
                        break;
                    }
                }
            }
            return ret;
        },
        dateTo8Str: function(value) {
            let ret = "";
            if (value instanceof Date) {
                ret =
                    value.getFullYear() +
                    ("0" + (value.getMonth() + 1)).slice(-2) +
                    ("0" + value.getDate()).slice(-2);
            }
            return ret;
        },
        convertToRequestData: function(obj) {
            let ret = {};
            for (const p in obj) {
                let val = obj[p];
                if (p.endsWith("_timestamp") && typeof val == "string") {
                    val = new Date(val);
                }
                if (val instanceof Date) {
                    if (p.endsWith("_date")) {
                        val = this.dateTo8Str(val);
                    } else {
                        val =
                            val.getFullYear() +
                            "-" +
                            ("0" + (val.getMonth() + 1)).slice(-2) +
                            "-" +
                            ("0" + val.getDate()).slice(-2) +
                            "T" +
                            ("0" + val.getHours()).slice(-2) +
                            ":" +
                            ("0" + val.getMinutes()).slice(-2) +
                            ":" +
                            ("0" + val.getSeconds()).slice(-2);
                    }
                } else if (val != null && typeof val == "object") {
                    val = this.convertFromResponseData(val);
                }
                ret[p] = val;
            }
            return ret;
        },
        convertFromResponseData: function(obj) {
            let ret = {};
            for (const p in obj) {
                ret[p] = this.convertFromRespnseValue(p, obj[p]);
            }
            return ret;
        },
        loadData: async function(url, func, errfunc) {
            return axios
                .get(url)
                .then(response => {
                    const d = this.convertFromResponseData(response.data);
                    func(d);
                })
                .catch(err => this.httpErrorHandler(err, errfunc));
        },
        loadList: async function(url, func, errfunc) {
            return axios
                .get(url)
                .then(response => {
                    let rd = response.data;
                    let dt = this.headDataToObj(rd.header, rd.data);
                    func(dt);
                })
                .catch(err => this.httpErrorHandler(err, errfunc));
        },
        signout: function() {
            sessionStorage.clear();
            let keycloak = this.$store.state.keycloak;
            this.$router.push({ name: "Home" });
            keycloak.logout();
            this.$store.dispatch("clearUserinfo");
            // this.$store.state.keycloak.logout("/");
            // let url =
            //     location.protocol +
            //     "://" +
            //     location.host +
            //     this.$router.resolve({ name: "Home" }).href;
            // let rurl = keycloak.createLogoutUrl({
            //     redirectUri: url
            // });
            // window.location.href = rurl;

            // console.log(
            //     "★★ " +
            //         this.$store.state.keycloak.createLogoutUrl({
            //             redirectUri: url
            //         })
            // );
            // this.$store.dispatch("signout", url);
        },
        getDivname: function(divkey, code) {
            let ret = "";
            if (code === null || code === undefined || code === "") {
                return ret;
            }
            let div = this.divs[divkey][code];
            if (div != null) {
                ret = div.contentlong;
            }
            return ret;
        },
        isManager: function() {
            let ret = false;
            let roles = this.$store.state.userinfo.roles;
            if (roles) {
                ret = roles.indexOf("mgr");
            }
            return ret > -1;
        },
        hasTicket: function(ticket) {
            let ret = false;
            const tps = this.__parseticket(ticket);
            const uif = this.$store.state.userinfo;
            if (uif) {
                const allows = uif.allow;
                ret = this.__isIncludeticket(allows, tps[0], tps[1]);
                if (ret) {
                    const denys = uif.deny;
                    ret = !this.__isIncludeticket(denys, tps[0], tps[1]);
                }
            }
            return ret;
        },
        hasRole: function() {
            let ret = false;
            const uif = this.$store.state.userinfo;
            if (uif) {
                const roles = uif.roles;
                if (roles) {
                    ret = roles && roles.length > 0;
                }
            }
            return ret;
        },
        __isIncludeticket: function(sources, tps0, tps1) {
            let ret = false;
            if (sources) {
                if (sources.indexOf("*") > -1) {
                    ret = true;
                } else {
                    for (const aticket of sources) {
                        let aps = this.__parseticket(aticket);
                        let ap1 = aps[1];
                        if (tps0 == aps[0]) {
                            if (ap1 == "*" || tps1 == "*") {
                                ret = true;
                                break;
                            } else {
                                const ts = tps1.split("");
                                ret = true;
                                for (const tt of ts) {
                                    if (ap1.indexOf(tt) < 0) {
                                        ret = false;
                                        break;
                                    }
                                }
                            }
                        }
                        if (ret) {
                            break;
                        }
                    }
                }
            }
            return ret;
        },
        __parseticket: function(ticket) {
            let ret = null;
            if (ticket == "*") {
                ret = ["*", "*"];
            } else if (ticket.indexOf("-") > 0) {
                ret = ticket.split("-");
            } else {
                ret = [ticket, "*"];
            }
            return ret;
        },
        getCurrentEmployee: function() {
            return this.$store.state.userinfo.employee;
        },
        getCompany: function() {
            return this.$store.state.company;
        },
        isUndefNull: function(val) {
            return val === undefined || val === null;
        },
        calcDepositPaymentDate: function(
            dateValue,
            cutoffDay,
            paymentTerm,
            paymentDay,
            laterDateFlag
        ) {
            let cday = cutoffDay;
            let payt = paymentTerm;
            let payd = paymentDay;
            let lflg = laterDateFlag;

            if (
                this.isUndefNull(dateValue) ||
                this.isUndefNull(cday) ||
                this.isUndefNull(payt) ||
                this.isUndefNull(payd)
            ) {
                return null;
            }

            let dt = new Date(dateValue.getTime());
            let omonth = dt.getMonth();
            if (cday < dt.getDate()) {
                omonth++;
            }
            omonth += parseInt(payt);
            dt.setDate(1);
            dt.setMonth(omonth);
            let rmon = dt.getMonth();
            dt.setDate(payd);
            if (rmon < dt.getMonth()) {
                dt.setDate(0);
            }
            // let dow = dt.getDay();
            // if (dow === 0 || dow == 6) {
            //     let dtt = dt.getDate();
            //     let ofs = 0;
            //     if (lflg) {
            //         ofs = dow / 6 + 1;
            //     } else {
            //         ofs = (dow - 6) / 6 - 1;
            //     }
            //     dt.setDate(dtt + ofs);
            // }
            let nbds = this.$store.state.bankNonBusinessDays;
            dt = this.searchValidBankDate(lflg, dt, nbds, 100);
            return dt;
        },
        searchValidBankDate: function(lflg, targetDate, nbds, limitLoop) {
            if (limitLoop < 0) {
                return null;
            }
            limitLoop--;
            let dt = targetDate;
            let dow = dt.getDay();
            if (dow === 0 || dow == 6) {
                let dtt = dt.getDate();
                let ofs = 0;
                if (lflg) {
                    ofs = dow / 6 + 1;
                } else {
                    ofs = (dow - 6) / 6 - 1;
                }
                dt.setDate(dtt + ofs);
            }
            let nbd = false;
            for (let b of nbds) {
                if (
                    b.getFullYear() == dt.getFullYear() &&
                    b.getMonth() == dt.getMonth() &&
                    b.getDate() == dt.getDate()
                ) {
                    nbd = true;
                    break;
                }
            }
            if (nbd) {
                // nbds.indexOf(dt) > -1) {
                let ofs = 0;
                if (lflg) {
                    ofs = 1;
                } else {
                    ofs = -1;
                }
                dt.setDate(dt.getDate() + ofs);
                dt = this.searchValidBankDate(lflg, dt, nbds, limitLoop);
            }
            return dt;
        },
        getCurrentTaxRate: function() {
            let txlist = this.$store.state.taxratelist;
            let ret = 0;
            if (txlist) {
                let trt = null;
                let today = new Date();
                today.setMilliseconds(0);
                today.setSeconds(0);
                today.setMinutes(0);
                today.setHours(0);
                let ttime = today.getTime();
                for (let tx of txlist) {
                    let txt = tx.start_date.getTime();
                    if (
                        txt <= ttime &&
                        (trt == null || trt.start_date.getTime() < txt)
                    ) {
                        trt = tx;
                    }
                }
                if (trt != null) {
                    ret = trt.tax_rate;
                }
            }
            return ret;
        },
        putData: function(url, data, func, errfunc) {
            let cdata = null;
            if (data instanceof Array) {
                cdata = [];
                for (let d of data) {
                    cdata.push(this.convertToRequestData(d));
                }
            } else {
                cdata = this.convertToRequestData(data);
            }

            this.requesting = true;
            return axios
                .put(url, cdata, func)
                .then(re => {
                    let rdata = this.convertFromResponseData(re.data);
                    // this.currentStep = 2;
                    let rfc = false;
                    if (func instanceof Function) {
                        rfc = func(rdata, re);
                    }
                    if (!rfc) {
                        this.$emit("updated", rdata);
                    }
                })
                .catch(err => this.httpErrorHandler(err, errfunc, "登録の失敗"))
                .then(() => {
                    this.requesting = false;
                });
        },
        postData: function(url, data, func, errfunc) {
            let cdata = null;
            if (data instanceof Array) {
                cdata = [];
                for (let d of data) {
                    cdata.push(this.convertToRequestData(d));
                }
            } else {
                cdata = this.convertToRequestData(data);
            }
            this.requesting = true;
            return axios
                .post(url, cdata)
                .then(re => {
                    let rdata = this.convertFromResponseData(re.data);
                    // this.currentStep = 2;
                    let rfc = false;
                    if (func instanceof Function) {
                        rfc = func(rdata);
                    }
                    if (!rfc) {
                        this.$emit("updated", rdata);
                    }
                })
                .catch(err => this.httpErrorHandler(err, errfunc, "登録の失敗"))
                .then(() => {
                    this.requesting = false;
                });
        },
        postMultipartData: function(url, formData, func, errfunc) {
            this.requesting = true;
            return axios
                .post(url, formData, {
                    headers: { "Content-Type": "multipart/form-data" }
                })
                .then(re => {
                    let rdata = this.convertFromResponseData(re.data);
                    // this.currentStep = 2;
                    let rfc = false;
                    if (func instanceof Function) {
                        rfc = func(rdata);
                    }
                    if (!rfc) {
                        this.$emit("updated", rdata);
                    }
                })
                .catch(err => this.httpErrorHandler(err, errfunc, "登録の失敗"))
                .then(() => {
                    this.requesting = false;
                });
        },
        deleteData: function(url, data, func, errfunc) {
            let cdata = this.convertToRequestData(data);
            this.requesting = true;
            return axios
                .delete(url, { data: cdata })
                .then(() => {
                    let rfc = false;
                    if (func instanceof Function) {
                        rfc = func(data);
                    }
                    if (!rfc) {
                        this.$emit("deleted", data);
                    }
                })
                .catch(err => this.httpErrorHandler(err, errfunc, "削除の失敗"))
                .then(() => {
                    this.requesting = false;
                });
        },
        downloadData: function(url, filename, errfunc) {
            return axios({
                url: url,
                method: "GET",
                responseType: "blob"
            })
                .then(response => {
                    const rurl = URL.createObjectURL(new Blob([response.data]));
                    const link = document.createElement("a");
                    link.href = rurl;
                    link.setAttribute("download", filename);
                    document.body.appendChild(link);
                    link.click();
                    //link.revokeObjectURL();
                })
                .catch(err => this.httpErrorHandler(err, errfunc));
        },
        httpErrorHandler: function(err, errfunc, title, message) {
            if (axios.isCancel(err)) {
                console.log("request is cancelled.");
            } else {
                let eres = err.response;
                if (eres) {
                    let msg = "";
                    let ttl = "通信エラー";
                    if (title) {
                        ttl = title;
                    }
                    if (eres.status >= 500) {
                        msg =
                            "サーバーエラー : システム管理者にお問い合わせください。";
                    } else if (message) {
                        msg = message;
                    } else {
                        let msgcode = eres.headers["mop-message-code"];
                        if (msgcode) {
                            let argstr = eres.headers["mop-message-args"];
                            let args = [];
                            if (argstr) {
                                args = argstr.split(",");
                            }

                            if (msgcode == 5001) {
                                msg =
                                    "データが最新ではないため処理を継続できません";
                            } else if (msgcode == 5101) {
                                let alen = args.length;
                                if (alen == 2) {
                                    let ym = args[0];
                                    let col = args[1].trim();
                                    if (terms[col]) {
                                        col = terms[col];
                                    }
                                    msg =
                                        ym.substring(0, 4) +
                                        "年" +
                                        ym.substring(4, 6) +
                                        "月（" +
                                        col +
                                        "）は締めらせているため処理を継続できません";
                                } else if (alen == 1) {
                                    let ym = args[0];
                                    msg =
                                        ym.substring(0, 4) +
                                        "年" +
                                        ym.substring(4, 6) +
                                        "月は締めらせているため処理を継続できません";
                                } else {
                                    msg = "システムにより処理が拒否されました";
                                }
                            } else {
                                msg = msgcode;
                            }
                        } else {
                            msg = eres.status + " " + eres.statusText + "\n";
                            msg += eres.data;
                        }
                    }
                    // alert(msg);
                    this.showMessageModal(msg, ttl, errfunc);
                }
            }
        },
        showMessageModal: function(message, title, onclose) {
            let mbox = this.$store.state.messageModal;
            mbox.onclose = onclose;
            mbox.message = message;
            mbox.title = title;
            mbox.show = true;
        },
        getAccountName: function() {
            return this.$store.state.userProfile.username;
        },
        round: function(number, precision) {
            var shift = function(number, precision, reverseShift) {
                if (reverseShift) {
                    precision = -precision;
                }
                var numArray = ("" + number).split("e");
                return +(
                    numArray[0] +
                    "e" +
                    (numArray[1] ? +numArray[1] + precision : precision)
                );
            };
            return shift(
                Math.round(shift(number, precision, false)),
                precision,
                true
            );
        },
        routePush: function(params) {
            let ret = false;
            let from = this.$route.fullPath;
            let to = this.$router.resolve(params).route.fullPath;
            if (from !== to) {
                this.$router.push(params);
                ret = true;
            }
            return ret;
        },
        routeGo: function(params) {
            this.$router.go(params);
            return true;
        },
        routeReplace: function(params) {
            let ret = false;
            let from = this.$route.fullPath;
            let to = this.$router.resolve(params).route.fullPath;

            if (from !== to) {
                this.$router.replace(to);
                ret = true;
            }
            return ret;
        },
        changeTheme: function(theme) {
            document.documentElement.setAttribute("theme", theme);
            localStorage.setItem("mop.theme", theme);
            this.$store.state.mopTheme.name = theme;
            this.$store.state.mopTheme.isDark = theme == "dark";
        },
        loadTheme: function() {
            let ret = localStorage.getItem("mop.theme");
            this.changeTheme(ret);
            return ret;
        },
        loadDepartmentAllForList: function() {
            let deps = this.department_all_for_list;
            let noget = Array.isArray(deps) && deps > 0;
            const now = new Date().getTime();
            if (noget) {
                if (now - this.department_all_for_list_at > 60000) {
                    noget = false;
                }
            }
            if (!noget) {
                this.readDataToArray(this.makeurl("dept/currentall"), list => {
                    this.department_all_for_list.length = 0;
                    for (let dept of list) {
                        this.department_all_for_list[dept.department_id] = dept;
                    }
                    this.department_all_for_list_at = now;
                });
            }
        },
        debounce :function (func, wait) {
            let timeout;
            return function(...args) {
                const context = this;
                clearTimeout(timeout);
                timeout = setTimeout(() => {
                    func.apply(context, args);
                }, wait);
            };
        }
    },
    watch: {
        "$store.state.mopTheme.isDark": function(val) {
            this.changeTheme(val ? "dark" : "light");
        }
    }
});
