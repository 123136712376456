var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"overlay"},[_c('div',{ref:"innerframe",staticClass:"innerframe"},[_c('button',{staticClass:"close-button",on:{"click":_vm.close}},[_vm._v("×")]),_c('div',{staticClass:"innerframe-header",on:{"mousedown":_vm.onMouseDownHeader}},[(_vm.title)?_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.title)+" ")]):_vm._e(),_vm._t("titlebar"),_c('div',{staticClass:"options"},[(_vm.steps)?_c('span',{staticClass:"stepIndicator"},_vm._l((_vm.steps),function(st,ix){return _c('span',{key:st.id},[(ix > 0)?_c('span',[_vm._v(" - ")]):_vm._e(),_c('span',{class:['aStep', { currentStep: ix == _vm.step }]},[_vm._v(_vm._s(st))])])}),0):_vm._e(),(
                        _vm.createButton ||
                            _vm.alterButton ||
                            _vm.changeButton ||
                            _vm.copyButton ||
                            _vm.removeButton
                    )?_c('span',{staticClass:"functions"},[(_vm.createButton)?_c('span',{on:{"click":_vm.onCreate}},[_c('IconBase',{attrs:{"iconName":"作成","width":"12","height":"12","visibleName":true}},[_c('IconWrite')],1)],1):_vm._e(),(_vm.alterButton)?_c('span',{on:{"click":_vm.onAlter}},[_c('IconBase',{attrs:{"iconName":"修正","width":"18","height":"18","visibleName":true}},[_c('IconPencil')],1)],1):_vm._e(),(_vm.changeButton)?_c('span',{on:{"click":_vm.onChange}},[_c('IconBase',{attrs:{"iconName":"変更","width":"18","height":"18","visibleName":true}},[_c('IconWrite')],1)],1):_vm._e(),(_vm.copyButton)?_c('span',{on:{"click":_vm.onCopy}},[_c('IconBase',{attrs:{"iconName":"複製","width":"18","height":"18","visibleName":true}},[_c('IconCopy')],1)],1):_vm._e(),(_vm.removeButton)?_c('span',{on:{"click":_vm.onRemove}},[_c('IconBase',{attrs:{"iconName":"削除","width":"18","height":"18","visibleName":true}},[_c('IconEraser')],1)],1):_vm._e()]):_vm._e()])],2),_c('div',{staticClass:"content"},[_vm._t("default",null,{"close":_vm.close,"step":_vm.changedStep,"steps":_vm.steps})],2)])])
}
var staticRenderFns = []

export { render, staticRenderFns }