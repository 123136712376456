<template>
    <div class="svgbutton">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            :width="width"
            :height="height"
            :viewBox="this.viewbox"
            :aria-labelledby="iconName"
            role="presentation"
            @click="$emit('click')"
        >
            <title :id="iconName" lang="en">{{ iconName }}</title>
            <g :fill="iconColor">
                <slot :viewbox="setViewbox" />
            </g>
        </svg>
        <div class="iconName" v-if="visibleName">{{ iconName }}</div>
    </div>
</template>

<script>
export default {
    name: "IconBase",
    props: {
        iconName: {
            type: String,
            default: ""
        },
        width: {
            type: [Number, String],
            default: 18
        },
        height: {
            type: [Number, String],
            default: 18
        },
        iconColor: {
            type: String,
            default: "currentColor"
        },
        viewBox: {
            type: String,
            default: "0 0 24 24"
        },
        visibleName: {
            type: Boolean,
            default: false
        }
    },
    data: function() {
        return {
            viewbox: this.viewBox
        };
    },
    methods: {
        setViewbox: function(val) {
            this.viewbox = val;
        }
    }
};
</script>

<style scoped>
svg {
    display: inline-block;
    vertical-align: baseline;
    margin-bottom: -2px; /* yes, I'm that particular about formatting */
}
.iconName {
    font-size: 0.65rem;
    text-align: center;
}
</style>
