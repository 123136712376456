<template>
    <div class="op-autocomplete">
        <label class="title" v-if="label" :for="id">{{ label }}</label>
        <div class="op-textfield">
            <input
                ref="targettextfield"
                type="text"
                v-model="inputModel"
                class="op-textfield-inner"
                autocomplete="off"
                @input="getSuggestionList"
                @change="change"
                @blur="blur"
                @keydown.down="down"
                @keydown.up="up"
                @keydown.enter="enter"
                :name="name"
                :id="id"
            />
            <button class="op-textfield-clear-button" @click="clear">×</button>
            <button
                class="op-textfield-open-button"
                @click="togggggle"
                @mousedown="mousedown"
                @mouseup="mouseup"
            >
                ▼
            </button>
        </div>
        <div v-if="required">
            必
            <span v-if="checkRequired">
                ✔
            </span>
            <span v-else>
                ×
            </span>
        </div>
        <div v-else>
            省略可
        </div>
        <div>
            文字数
            <span v-if="minlength > 1">{{ minlength }} ≦ </span>
            {{ remining }}
            <span v-if="maxlength < Number.MAX_VALUE">
                ／ {{ maxlength }}
            </span>
            <span v-if="checkTextLength">✔</span>
            <span v-else>×</span>
        </div>
        <div v-if="regexPattern">
            {{ this.patternMsg }}
            <span v-if="checkPattern">✔</span>
            <span v-else>×</span>
        </div>
        <div
            class="op-autocomplete-listpanel"
            v-if="results.length > 0 && openflag"
        >
            <ul class="list-group" style="">
                <li
                    v-for="(result, index) in results"
                    :key="suggestKey(result)"
                    class="list-group-item list-group-item-action"
                    @mousedown="mousedown"
                    @mouseup="mouseup"
                    @mouseleave="mouseleave"
                    @click="suggestionClick(result)"
                    @mouseover="mouseOver(index)"
                    :class="{ 'autocomplete-list-active': index === current }"
                >
                    {{ suggestDecoValue(result) }}
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: "AutoComplete",
    model: {
        prop: "compoModel",
        event: "input"
    },
    props: {
        compoModel: Object,
        suggest: Function,
        suggestValue: Function,
        suggestKey: Function,
        required: Boolean,
        allowNewValue: Boolean,
        name: String,
        maxlength: { default: Number.MAX_VALUE },
        minlength: { default: 0, type: Number },
        pattern: String,
        patternMessage: String,
        extraCheck: Function,
        id: String,
        label: String
    },
    data: function() {
        return {
            inputModel: this.suggestDecoValue(this.compoModel),
            objectModel: this.compoModel,
            results: [],
            openflag: false,
            current: 0,
            blurflag: true,
            errors: new Set(),
            validflag: true,
            regexPattern: null,
            patternMsg: ""
        };
    },
    methods: {
        getSuggestionList: function() {
            this.results.length = 0;
            this.suggest(this.inputModel, this.results);
            // this.openflag = this.results.length > 0;
        },
        suggestionClick: function(res) {
            this.blurflag = true;
            this.decide(res);
            this.openflag = false;
            this.current = 0;
            this.results.length = 0;
            this.results.push(res);
            // this.$emit("input", this.radioModel);
        },
        suggestDecoValue: function(result) {
            return this.suggestValue(result);
        },
        mouseOver: function(index) {
            this.current = index;
        },
        blur: function() {
            if (this.blurflag) {
                this.openflag = false;
                this.enter();
            }
        },
        mousedown: function() {
            this.blurflag = false;
        },
        mouseup: function() {},
        mouseleave: function() {
            if (!this.blurflag) {
                this.blurflag = true;
                this.openflag = false;
            }
        },
        up: function() {
            if (this.current > 0) {
                this.current--;
            } else {
                this.current--;
                this.openflag = false;
            }
        },
        togggggle: function() {
            // if (!this.openflag) {
            this.blurflag = true;
            if (!this.openflag) {
                this.openflag = true;
                this.getSuggestionList();
            } else {
                this.openflag = false;
            }
            this.$refs.targettextfield.focus();
        },
        down: function() {
            if (!this.openflag) {
                this.openflag = true;
                this.getSuggestionList();
                this.$refs.targettextfield.focus();
                this.current = 0;
            } else {
                if (this.current < this.results.length - 1) this.current++;
            }
        },
        clear: function() {
            this.inputModel = "";
            this.current = -1;
            this.$refs.targettextfield.focus();
            if (this.openflag) {
                this.getSuggestionList();
            }
        },
        change: function() {
            // if (!this.openflag) {
            //     this.openflag = true;
            //     this.current = 0;
            // }
        },
        enter: function() {
            if (this.openflag) {
                if (this.results[this.current]) {
                    let res = this.results[this.current];
                    this.decide(res);
                    this.results.length = 0;
                    this.results.push(res);
                    this.openflag = false;
                    this.current = 0;
                }
            } else if (this.results.length == 1) {
                this.current = 0;
                let res = this.results[this.current];
                this.decide(res);
            }
        },
        decide: function(res) {
            this.inputModel = this.suggestDecoValue(res);
            this.objectModel = res;
            this.$emit("input", res);
        },
        settingErrors: function(name, isvalid) {
            let cols = this.errors;
            if (isvalid) {
                if (cols.has(name)) {
                    cols.delete(name);
                }
            } else {
                if (!cols.has(name)) {
                    cols.add(name);
                }
            }
            this.validflag = cols.size < 1;
        },
        _checkRequired() {
            let ret = true;
            if (this.required) {
                ret =
                    this.inputModel != null &&
                    this.inputModel.trim().length > 0;
                this.settingErrors("required", ret);
            }
            return ret;
        },
        _checkTextLength() {
            let ret = true;
            if (this.inputModel != null) {
                let tlen = this.inputModel.trim().length;
                if (tlen > this.maxlength) {
                    ret = false;
                }
                if (ret && tlen < this.minlength) {
                    ret = false;
                }
            }
            this.settingErrors("length", ret);
            return ret;
        },
        _initCheckPattern() {
            let tp = this.pattern;
            if (tp) {
                if (tp == "09") {
                    this.regexPattern = /^[0-9]*$/;
                    this.patternMsg = "半角数字のみ";
                } else if (tp == "az") {
                    this.regexPattern = /^[a-zA-Z]*$/;
                    this.patternMsg = "半角英字のみ";
                } else if (tp == "az09" || tp == "09az") {
                    this.regexPattern = /^[a-zA-Z0-9]*$/;
                    this.patternMsg = "半角英数字のみ";
                } else if (tp.startsWith("re:")) {
                    this.regexPattern = new RegExp(tp.substring(3));
                    this.patternMsg = this.patternMessage;
                }
            }
        },
        _checkPattern() {
            let ret = true;
            if (this.regexPattern) {
                if (this.inputModel != null) {
                    ret = this.regexPattern.test(this.inputModel);
                }
                this.settingErrors("pattern", ret);
            }
            return ret;
        },
        _checkExists() {
            let ret = true;
            // if (!this.allowNewValue) {
            // }
            return ret;
        }
    },
    computed: {
        checkRequired() {
            return this._checkRequired();
        },
        checkTextLength() {
            return this._checkTextLength();
        },
        checkPattern() {
            return this._checkPattern();
        },
        remining: function() {
            let tlen = 0;
            if (this.inputModel != null) {
                tlen = this.inputModel.trim().length;
            }
            return tlen;
        }
    },
    mounted: function() {
        this._checkRequired();
        this._checkTextLength();
        this._initCheckPattern();
        this._checkPattern();
    },
    watch: {
        validflag: function(value) {
            if (this.name) {
                this.$emit("changed-valid", this.name, value);
            }
        }
    },
    created: function() {
        if (!this.id) {
            this.id = "op" + this.$store.getters.nextIdseq;
        }
    }
};
</script>
