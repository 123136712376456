<template>
    <div class="form-field op-checkbox">
        <div class="form-field-input">
            <input
                type="checkbox"
                @change="inputChange"
                v-model="dataModel"
                :name="name"
                :id="myid"
            />
            <label :for="myid">
                <span
                    :class="[
                        { 'checkbox-bottomlabel': styleType == 'bottomlabel' }
                    ]"
                    >{{ label }}</span
                >
            </label>
        </div>
    </div>
</template>

<script>
export default {
    name: "OpCheckbox",
    model: {
        prop: "inputModel",
        event: "input"
    },
    props: {
        inputModel: Boolean,
        name: String,
        label: String,
        styleType: String,
        id: String
    },
    data: function() {
        return {
            dataModel: this.inputModel,
            // validflag: false,
            myid: this.id
        };
    },
    watch: {
        inputModel: function(val) {
            this.dataModel = val;
        }
    },
    methods: {
        inputChange: function() {
            this.$emit("input", this.dataModel);
            this.$emit("change", this.dataModel);
        }
    },
    created: function() {
        if (!this.myid) {
            this.$store.commit("inclementIdseq");
            this.myid = "mopcheckbox" + this.$store.state.idseq;
        }
    }
};
</script>
<style scoped>
.checkbox-bottomlabel {
    display: block;
    font-size: 0.75rem;
    padding-top: 2px;
}
</style>
