<template>
    <div
        :class="['op-autocomplete', 'form-field', { disabled: disabled }]"
        :style="blockStyle"
    >
        <label class="title" v-if="label && !hideheader" :for="myid">{{
            label
        }}</label>
        <div class="restrict" v-if="!readonly && !hideheader">
            <div
                v-if="required"
                :class="[
                    'restrict-item',
                    checkRequired ? 'checked-ok' : 'checked-error'
                ]"
            >
                必
            </div>
            <div
                class="restrict-item"
                :class="[
                    'restrict-item',
                     exists ? 'checked-ok' : 'checked-error'
                ]"
            >
                存在確認
            </div>
        </div>
        <div class="form-field-input op-textfield">
            <div v-if="readonly" class="readonly-field">
                {{ inputModel }}
            </div>
            <input
                v-else
                ref="targettextfield"
                type="text"
                v-model="inputModel"
                class="op-textfield-inner"
                autocomplete="off"
                @input="inputSuggest"
                @change="change"
                @blur="blur"
                @keydown.down="down"
                @keydown.up="up"
                @keydown.enter="enter"
                @keydown.esc="onEsc"
                @keydown.tab="onTab"
                :name="name"
                :id="myid"
                :disabled="disabled"
            />
            <button
                v-if="!readonly"
                class="op-textfield-clear-button"
                @click="clear"
                tabindex="-1"
                :disabled="disabled"
            >
                ×
            </button>
            <button
                v-if="!readonly"
                class="op-textfield-open-button"
                @click="togggggle"
                @mousedown="mousedown"
                @mouseup="mouseup"
                tabindex="-1"
                :disabled="disabled"
            >
                ↓
            </button>
            <div
                class="op-autocomplete-listpanel"
                v-if="results.length > 0 && openflag"
            >
                <ul class="list-group" style="">
                    <li
                        v-for="(result, index) in results"
                        :key="suggestKey(result)"
                        class="list-group-item list-group-item-action"
                        @mousedown="mousedown"
                        @mouseup="mouseup"
                        @mouseleave="mouseleave"
                        @click="suggestionClick(result)"
                        @mouseover="mouseOver(index)"
                        :class="{
                            'autocomplete-list-active': index === current
                        }"
                    >
                        {{ suggestDecoValue(result) }}
                    </li>
                </ul>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "OpSelect",
    model: {
        prop: "compoModel",
        event: "input"
    },
    props: {
        compoModel: [Object, String, Number],
        suggest: Function,
        suggestValue: Function,
        suggestKey: Function,
        required: Boolean,
        disabled: Boolean,
        name: String,
        maxlength: { default: Number.MAX_VALUE },
        minlength: { default: 0, type: Number },
        maxwidth: String,
        pattern: String,
        patternMessage: String,
        extraCheck: Function,
        isabled: Boolean,
        id: String,
        label: String,
        readonly: Boolean,
        hideheader: Boolean,
        version: { default: 0, type: Number }
    },
    data: function() {
        return {
            inputModel: this.suggestDecoValue(this.compoModel),
            objectModel: { ...this.compoModel },
            results: [],
            openflag: false,
            current: 0,
            blurflag: true,
            errors: new Set(),
            validflag: true,
            myid: this.id,
            exists: true,
            blockStyle: {
                maxWidth: this.getInitMaxwidth(),
                width: "100%"
            }
        };
    },
    methods: {
        focus: function() {
            this.$refs.targettextfield.focus();
        },
        getInitMaxwidth: function() {
            let ret = "";
            if (this.maxwidth) {
                ret = this.maxwidth;
            } else {
                let len = this.maxlength;
                if (len > 0) {
                    ret = len * 1.2 + "ex";
                }
            }
            return ret;
        },
        inputSuggest: function() {
            this.getSuggestionList(false);
        },
        getSuggestionList: function(flag, func) {
            this.results.length = 0;
            if (this.suggest.constructor.name === "AsyncFunction") {
                this.suggest(this.inputModel, this.results, flag).then(res =>{
                    if (typeof res == "number") {
                        this.current = res;
                    } else {
                        this.current = 0;
                    }
                    if (func) {
                        func();
                    }
                });
            } else {
                let res = this.suggest(this.inputModel, this.results, flag);
                if (func) {
                        func();
                    }
                if (typeof res == "number") {
                    this.current = res;
                } else {
                    this.current = 0;
                }
            }
            // this.openflag = this.results.length > 0;
        },
        suggestionClick: function(res) {
            this.blurflag = true;
            this.decide(res);
            this.openflag = false;
            this.current = 0;
            this.results.length = 0;
            this.results.push(res);
            this.$refs.targettextfield.focus();
            // this.$emit("input", this.radioModel);
        },
        suggestDecoValue: function(result) {
            return this.suggestValue(result);
        },
        mouseOver: function(index) {
            this.current = index;
        },
        blur: function() {
            if (this.blurflag) {
                // this.openflag = false;
                this.enter();
            } 
        },
        mousedown: function() {
            this.blurflag = false;
        },
        mouseup: function() {},
        mouseleave: function() {
            if (!this.blurflag) {
                this.blurflag = true;
                this.openflag = false;
            }
        },
        up: function() {
            if (this.current > 0) {
                this.current--;
            } else {
                this.current--;
                this.openflag = false;
            }
        },
        togggggle: function() {
            // if (!this.openflag) {
            this.blurflag = true;
            if (!this.openflag) {
                this.openflag = true;
                this.getSuggestionList(true);
            } else {
                this.openflag = false;
            }
            this.$refs.targettextfield.focus();
        },
        down: function() {
            if (!this.openflag) {
                this.openflag = true;
                this.getSuggestionList(true);
                this.$refs.targettextfield.focus();
                // this.current = 0;
            } else {
                if (this.current < this.results.length - 1) this.current++;
            }
        },
        clear: function() {
            this.decide(null);
            this.current = -1;
            this.$refs.targettextfield.focus();
            if (this.openflag) {
                this.getSuggestionList(true);
            } else {
                this.results.length = 0;
            }
        },
        change: function() {
            // if (!this.openflag) {
            //     this.openflag = true;
            //     this.current = 0;
            // }
        },
        enter: function() {
            if (this.openflag) {
                if (this.results[this.current]) {
                    let res = this.results[this.current];
                    this.decide(res);
                    this.results.length = 0;
                    this.results.push(res);
                    this.openflag = false;
                    this.current = 0;
                }
            } else if (this.inputModel != "") {
                let res = "";
                switch(this.results.length) {
                    case 0:
                        break;
                    case 1:
                        res = this.results[this.current];
                        this.inputModel = this.suggestDecoValue(res);
                        if (
                            this.suggestDecoValue(res) !=
                            this.suggestDecoValue(this.objectModel)
                        ) {
                            this.decide(res);
                        }
                        break;
                    default:
                        this.down();
                }
            }
        },
        onEsc: function() {
            this.openflag = false;
        },
        onTab: function() {
            this.openflag = false;
        },
        decide: function(res) {
            this.inputModel = this.suggestDecoValue(res);
            this.objectModel = res;
            this._checkExists();
            this.$emit("input", res);
        },
        settingErrors: function(name, isvalid) {
            let cols = this.errors;
            if (isvalid) {
                if (cols.has(name)) {
                    cols.delete(name);
                }
            } else {
                if (!cols.has(name)) {
                    cols.add(name);
                }
            }
            this.validflag = cols.size < 1;
        },
        _checkRequired() {
            let ret = true;
            if (this.required) {
                ret =
                    this.inputModel != null &&
                    this.inputModel.trim().length > 0;
                this.settingErrors("required", ret);
            }
            return ret;
        },
        _checkExists() {
            let ret = true;
            if (this.inputModel && !this.allowNewValue) {
                ret = false;
                for(const obj of this.results) {
                    ret = this.inputModel == this.suggestDecoValue(obj);
                    if (ret) break;
                }
                this.settingErrors("exists", ret);
            }
            this.exists = ret;
            return ret;
        },
    },
    computed: {
        checkRequired() {
            return this._checkRequired();
        },
        checkExists() {
            return this._checkExists();
        }
    },
    mounted: function() {
        this.getSuggestionList(true, ()=>{
            this._checkRequired();
            this._checkExists();
            this.blurflag = false;
            this.$emit("changed-valid", this.name, this.validflag);
        });

    },
    watch: {
        inputModel : function() {
            this._checkExists();
        },
        validflag: function(value) {
            if (this.name) {
                this.$emit("changed-valid", this.name, value);
            }
        },
        compoModel: function(val) {
            this.getSuggestionList(true, ()=>{
                this.decide(val);
                this._checkRequired();
                this._checkExists();
                this.blurflag = false;
                this.$emit("changed-valid", this.name, this.validflag);
            });
        },
        disabled: function(value) {
            if (value) {
                this.validflag = true;
            } else {
                this._checkRequired();
                this._checkExists();
            }
        },
        version: function() {
            this.getSuggestionList(true, () => {
                this._checkExists();
            });
        }
    },
    created: function() {
        if (!this.myid) {
            this.$store.commit("inclementIdseq");
            this.myid = "oppo2020" + this.$store.state.idseq;
        }
    }
};
</script>

<style scoped>
.op-autocomplete {
    position: relative;
}
.op-autocomplete .op-textfield-inner {
    padding-right: 60px;
}
.op-autocomplete .op-textfield-clear-button {
    right: 40px;
}
.op-autocomplete-listpanel {
    position: absolute;
    z-index: 20;
    top: 0;
    margin-top: 40px;
    background-color: var(--main-context-bg-color);
    border: 1px solid var(--border-color);
    padding: 5px;
    /* width: calc(100% - 40px); */
    box-sizing: border-box;
}
.op-autocomplete-listpanel ul {
    list-style-type: none;
    list-style-position: unset;
    padding: 0 0;
    margin: 0;
    border-style: none;
    box-sizing: border-box;
    /* background-color: var(--main-context-bg-color); */
}
.op-autocomplete-listpanel ul li {
    padding: 5px 10px;
}
.autocomplete-list-active {
    background-color: var(--input-selected-bg-color);
    border-radius: 5px;
    color: var(--input-selected-text-color);
}
</style>
