var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['form-field', { disabled: _vm.disabled }],style:(_vm.blockStyle)},[(_vm.label)?_c('label',{staticClass:"title",attrs:{"for":_vm.myid}},[_vm._v(_vm._s(_vm.label))]):_vm._e(),(_vm.visibleRestrict())?_c('div',{staticClass:"restrict"},[(_vm.required)?_c('div',{class:[
                'restrict-item',
                _vm.checkRequired ? 'checked-ok' : 'checked-error'
            ]},[_vm._v(" 必 ")]):_vm._e(),(_vm.regexPattern)?_c('div',{class:[
                'restrict-item',
                _vm.checkPattern ? 'checked-ok' : 'checked-error'
            ]},[_vm._v(" "+_vm._s(this.patternMsg)+" ")]):_vm._e(),(_vm.fixlength > 0)?_c('div',{class:[
                'restrict-item',
                _vm.checkTextLength ? 'checked-ok' : 'checked-error'
            ]},[_vm._v(" "+_vm._s(_vm.fixlength)+"文字 ")]):(_vm.minlength > 1)?_c('div',{class:[
                'restrict-item',
                _vm.checkTextLength ? 'checked-ok' : 'checked-error'
            ]},[_vm._v(" "+_vm._s(_vm.minlength)+" 文字以上 ")]):_vm._e()]):_vm._e(),_c('div',{style:(_vm.fieldStyle)},[_c('div',{staticClass:"form-field-input op-textfield"},[(_vm.readonly)?_c('div',{staticClass:"readonly-field"},[_vm._v(" "+_vm._s(_vm.textModel)+" ")]):(_vm.type == 'textarea')?_c('textarea',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.textModel),expression:"textModel"}],ref:"input",staticClass:"op-textfield-inner",style:(_vm.inputStyle),attrs:{"id":_vm.myid,"disabled":_vm.disabled},domProps:{"value":(_vm.textModel)},on:{"change":_vm.inputChange,"blur":_vm.blurInput,"input":function($event){if($event.target.composing)return;_vm.textModel=$event.target.value}}},_vm.inputListeners)):_c('input',_vm._g({directives:[{name:"model",rawName:"v-model",value:(_vm.textModel),expression:"textModel"}],ref:"input",staticClass:"op-textfield-inner",attrs:{"type":"text","maxlength":_vm.getMaxlength(),"id":_vm.myid,"disabled":_vm.disabled},domProps:{"value":(_vm.textModel)},on:{"change":_vm.inputChange,"blur":_vm.blurInput,"input":function($event){if($event.target.composing)return;_vm.textModel=$event.target.value}}},_vm.inputListeners)),(!_vm.readonly)?_c('button',{class:[
                    'op-textfield-clear-button',
                    { 'textarea-clear-button': _vm.type == 'textarea' }
                ],attrs:{"type":"button","disabled":_vm.disabled,"tabindex":"-1"},on:{"click":function($event){return _vm.clear()}}},[_vm._v(" × ")]):_vm._e()]),(!_vm.readonly)?_c('div',{staticStyle:{"white-space":"pre-wrap"}},[_c('div',{class:[
                    'charscounter',
                    _vm.checkTextLength ? 'checked-ok' : 'checked-error'
                ]},[_vm._v(" 文字数 "+_vm._s(_vm.remining)+" "),(_vm.maxlength > 0)?_c('span',[_vm._v(" ／ "+_vm._s(_vm.maxlength)+" ")]):_vm._e()]),_c('span',{staticClass:"fieldnote"},[_vm._v(" "+_vm._s(_vm.note)+" ")])]):_vm._e(),_c('div')])])
}
var staticRenderFns = []

export { render, staticRenderFns }